/* eslint-disable operator-linebreak */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import * as api from "utils/api";
import { formatDate } from "utils/dateHelpers";
import { Page } from "components/Page";
import {
    Section,
    SectionTitle,
    SectionContent,
    SectionFooter,
    SectionFooterButton,
} from "components/Page/Section";
import TextField from "components/MaterialUi/TextField";
import SuccessAlert from "components/Alerts";
import HealthDisplay from "components/HealthDisplay";
import { DETAILS_SECTION } from "./mediaAccountDetailsConstants";
import UpdateMediaAccountDialog from "./UpdateMediaAccountDialog/updateMediaAccountDialog";
import InternalMediaAccountDetailsForm from "./InternalMediaAccountDetailsForm/InternalMediaAccountDetailsForm";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 5px;
`;
Row.displayName = "Row";

const Label = styled(Typography)`
    font-weight: 600;
    min-width: 200px;
`;
Label.displayName = "Label";

/* TODO: Convert to using useReducer / custom hook to manage state better
*   & to not follow this pattern for future development */
const MediaAccountDetails = () => {
    const [accountDetails, setMediaAccountDetails] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [displayEditForm, setDisplayEditForm] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [formErrors, setFormError] = useState("");

    const { companyId, mediaAccountId } = useParams();

    const toggleEditForm = () => setDisplayEditForm(!displayEditForm);

    const updatedCompanyName = ({ value }) => {
        setMediaAccountDetails({ ...accountDetails, name: value });
        setUnsavedChanges(true);
    };

    const updatedHarvestingAddress = ({ value }) => {
        setMediaAccountDetails({ ...accountDetails, harvestingEmailAddress: value });
        setUnsavedChanges(true);
    };

    const updateResult = (result, err) => {
        if (result) {
            setFormError("");
            setUnsavedChanges(false);
            setSaveSuccessful(result);
            return setTimeout(() => setSaveSuccessful(false), 5000);
        }
        return setFormError(err.errors);
    };

    React.useEffect(() => {
        setLoading(true);
        const loadMediaAccountDetails = async () => {
            try {
                const response = await api.get(`media-accounts/${companyId}/${mediaAccountId}`);
                const payload = response.data;
                setMediaAccountDetails(payload);
                setError(null);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        loadMediaAccountDetails();
    }, [mediaAccountId, companyId]);

    return (
        <Page loading={loading} error={error}>
            <Section id={DETAILS_SECTION}>
                <SectionTitle title={accountDetails.name} hideBack={false} />
                <SectionContent>
                    <HealthDisplay
                        healthy={accountDetails.health && accountDetails.health.healthy}
                        reason={accountDetails.health && accountDetails.health.reason}
                    />
                    <Row key="channel">
                        <Label variant="subtitle2">Channel:</Label>
                        <Typography
                            variant="body1"
                            data-testid="account-detail-channel"
                        >
                            {accountDetails.channel}
                        </Typography>
                    </Row>
                    <Row key="name">
                        <Label variant="subtitle2">Name:</Label>
                        <TextField
                            error={!!formErrors.Name}
                            defaultValue={accountDetails.name}
                            color="secondary"
                            onChange={({ target }) => updatedCompanyName(target)}
                            helperText={formErrors.Name ? formErrors.Name[0] : null}
                        />
                    </Row>
                    <Row key="createdBy">
                        <Label variant="subtitle2">Created By:</Label>
                        <Typography
                            variant="body1"
                            data-testid="account-detail-channel"
                        >
                            {accountDetails.addedBy}
                        </Typography>
                    </Row>
                    <Row key="created">
                        <Label variant="subtitle2">Created:</Label>
                        <Typography
                            variant="body1"
                            data-testid="account-detail-channel"
                        >
                            {formatDate(accountDetails.added)}
                        </Typography>
                    </Row>
                    {accountDetails.harvestingEmailAddress !== undefined &&
                    <InternalMediaAccountDetailsForm
                        value={accountDetails.harvestingEmailAddress}
                        onChange={({ target }) => updatedHarvestingAddress(target)}
                        error={!!formErrors.HarvestingAddress}
                        helperText={formErrors.HarvestingAddress
                            ? formErrors.HarvestingAddress[0] : null}
                    />}
                </SectionContent>
                <SectionFooter>
                    <SectionFooterButton
                        disabled={!unsavedChanges}
                        onClick={toggleEditForm}
                        buttonText="UPDATE"
                    />
                </SectionFooter>
                {!loading &&
                <UpdateMediaAccountDialog
                    open={displayEditForm}
                    handleClose={toggleEditForm}
                    companyId={companyId}
                    channel={accountDetails.channel}
                    mediaAccountId={accountDetails.id}
                    name={accountDetails.name}
                    notifySuccess={updateResult}
                    harvestingAddress={accountDetails.harvestingEmailAddress}
                    internalCheck={accountDetails.harvestingEmailAddress !== undefined}
                />}
                <SuccessAlert message="Media account details updated" show={saveSuccessful} />
            </Section>
        </Page>
    );
};

export default MediaAccountDetails;
