import React, { useState } from "react";
import PropTypes from "prop-types";
import { AuthProvider } from "authorization/AuthContext";
import Error from "components/Error/Error";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import * as api from "utils/api";

const Authorization = ({ children }) => {
    const [loginResult, setLoginResult] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const authenticate = async () => {
        try {
            await api.get("authentication/login")
                .then(async (result) => {
                    setLoginResult(result);
                    setLoading(false);
                });

        } catch (err) {
            setError(err.message);
        }
    };

    React.useEffect(() => {
        authenticate();
    }, []);

    if (error) {
        return (
            <Error error={error} />
        );
    }

    if (loading) {
        return (
            <LoadingSpinner />
        );
    }

    if (loginResult) {
        return (
            <AuthProvider value={loginResult}>
                {children}
            </AuthProvider>
        );
    }

    return null;
};

Authorization.propTypes = {
    children: PropTypes.node,
};

export default Authorization;
