import React from "react";
import styled from "styled-components";
import {
    List as MuiList,
    ListItem,
    ListItemText as MuiListItemText,
    ListItemAvatar,
    Avatar as MuiAvatar,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useAuthContext } from "authorization/AuthContext";

export const UserPanel = styled.div`
    height: 75px;
`;

const User = styled(MuiList)`
    padding: 0px;
    width: 100%;
    background: ${({ theme }) => theme.greyscale.grey5};
`;

const LogOutButton = styled(ExitToAppIcon)`
    cursor: pointer;
`;
LogOutButton.displayName = "LogOutButton";

const Avatar = styled(MuiAvatar)`
    text-transform: uppercase;
    color: ${({ theme }) => theme.greyscale.white};
    background-color: ${({ theme }) => theme.brand};
`;

const UserInformation = styled(MuiListItemText)`
    overflow: hidden

    .MuiTypography-colorTextSecondary {
        color: ${({ theme }) => theme.brand};
    }
`;

const UserPanelContainer = () => {
    const user = useAuthContext();

    const handleLogout = () => {
        window.location = "/api/v1.0/authentication/logout";
    };

    const initials = user.name.split(" ").map((x) => x.substring(0, 1)).join("");

    return (
        <UserPanel>
            <User component="nav">
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            {initials}
                        </Avatar>
                    </ListItemAvatar>
                    <UserInformation
                        primary={user.name}
                        secondary={user.preferredUsername}
                    />
                    <LogOutButton onClick={handleLogout} />
                </ListItem>
            </User>
        </UserPanel>
    );
};

export default UserPanelContainer;
