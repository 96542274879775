import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import ActionDialog from "components/ActionDialog";
import { DialogContent } from "@material-ui/core";

const DialogContentContainer = styled.div`
    text-align: center;
`;
DialogContentContainer.displayName = "DialogActionsContainer";

const DeleteAliasDomainDialog = ({
    domain, open, onClose, onDelete,
}) => (
    <ActionDialog
        title={`Delete ${domain}?`}
        open={open}
        onClose={onClose}
    >
        <DialogContentContainer>
            <DialogContent>
                <span>This will permanently delete the alias domain.</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDelete} color="primary">Confirm</Button>
            </DialogActions>
        </DialogContentContainer>
    </ActionDialog>
);

DeleteAliasDomainDialog.propTypes = {
    domain: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default DeleteAliasDomainDialog;
