import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const SearchBox = styled.div`
    display: flex;
    border: solid 2px ${({ theme }) => theme.greyscale.grey4};
    margin-bottom: 10px;
`;

const Icon = styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: ${({ theme }) => theme.greyscale.grey3};
`;

const Field = styled(TextField)`
    border: 0;
    flex: 1 1 auto;
    height: 46px;
    margin-right: 10px;

    div {
        height: 100%;

        &.MuiInput-underline:hover:before,
        &.MuiInput-underline:before {
            border:0;
        }

        &.MuiInput-underline:hover:after,
        &.MuiInput-underline:after {
            border:0;
        }
    }
`;

const SearchBoxContainer = ({
    color, placeholder, type, fullWidth, onChange,
}) => (
    <SearchBox>
        <Icon>
            <SearchIcon />
        </Icon>
        <Field
            color={color}
            placeholder={placeholder}
            type={type}
            fullWidth={fullWidth}
            onChange={onChange}
        />
    </SearchBox>
);

SearchBoxContainer.propTypes = {
    color: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
};

SearchBoxContainer.defaultProps = {
    placeholder: "I'm searching for...",
};

export default SearchBoxContainer;
