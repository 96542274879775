const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
};

const requestDetails = (details, useDefaultHeaders = true) => {

    const request = {
        credentials: "same-origin",
        ...details,
    };

    if (useDefaultHeaders) {
        request.headers = headers;
    }

    return request;
};

const apiFetch = (url, details, retainHeaders = false) => fetch(`/api/v1.0/${url}`,
    requestDetails(details, !retainHeaders))
    .then(async (response) => {
        if (!response.ok) {
            const challenge = response.headers.get("X-Gnatta-Challenge");
            if (challenge) {
                window.location = challenge;
            } else if (response.status >= 405) {
                throw new Error("serverError");
            } else {
                throw await response.json();
            }
        }
        if (response.status === 204) {
            return {};
        }

        return response.json();
    });

export const get = (url) => apiFetch(url);

export const postFile = (url, body) => apiFetch(url, { method: "POST", body }, true);

export const post = (url, body) => apiFetch(url, { method: "POST", body: JSON.stringify(body) });

export const put = (url, body) => apiFetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
});

export default {
    get,
    post,
    postFile,
    put,
};
