import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Authorization from "authorization";
import { SiteHeader, SiteContent } from "domain/Site";
import AccessDenied from "domain/AccessDenied";
import CompanyRouter from "./CompanyRouter";
import * as Constants from "./RouterConstants";

const RouterContainer = () => (
    <BrowserRouter>
        <SiteHeader />
        <Switch>
            <Route exact path={`/${Constants.DENIED}`}>
                <AccessDenied />
            </Route>
            <Route path="*">
                <Authorization>
                    <SiteContent>
                        <CompanyRouter />
                    </SiteContent>
                </Authorization>
            </Route>
        </Switch>
    </BrowserRouter>
);

export default RouterContainer;
