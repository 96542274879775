import PropTypes from "prop-types";
import styled from "styled-components";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";

const DeleteButton = styled.button`
    display: flex;
    background-color: ${({ theme }) => theme.greyscale.white};
    border: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
        width: 1.2rem;
        height: 1.2rem;
        fill: ${({ theme }) => theme.greyscale.grey1};
    }
`;
DeleteButton.displayName = "DeleteButton";

function SmallDeleteButton({ onDelete }) {
    return (
        <DeleteButton onClick={onDelete}>
            <ClearIcon />
        </DeleteButton>
    );
}

SmallDeleteButton.propTypes = {
    onDelete: PropTypes.func,
};

export default SmallDeleteButton;
