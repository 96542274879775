import * as constants from "./optionsSectionConstants";

export const setSavedOptions = (savedOptions) => ({
    type: constants.SET_SAVED_OPTIONS,
    payload: { savedOptions },
});

export const setOptions = (options) => ({
    type: constants.SET_OPTIONS,
    payload: { options },
});

export const addOrUpdateOption = (key, value) => ({
    type: constants.ADD_OR_UPDATE_OPTION,
    payload: { key, value },
});

export const removeOption = (key) => ({
    type: constants.REMOVE_OPTION,
    payload: { key },
});

export const setSearchTerm = (searchTerm) => ({
    type: constants.SET_SEARCH_TERM,
    payload: { searchTerm },
});

export const setSaving = (saving) => ({
    type: constants.SET_SAVING,
    payload: { saving },
});

export const setShowSuccessAlert = (showSuccessAlert) => ({
    type: constants.SET_SHOW_SUCCESS_ALERT,
    payload: { showSuccessAlert },
});

export const setErrorMessage = (errorMessage) => ({
    type: constants.SET_ERROR_MESSAGE,
    payload: { errorMessage },
});

export const setModalUse = (modalUse) => ({
    type: constants.SET_MODAL_USE,
    payload: { modalUse },
});
