import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
    Select, MenuItem, Button, Box, Typography,
} from "@material-ui/core";
import { useCompanyContext } from "context/CompanyContext";

const ImportOptionsWrapper = styled.div`
    display: flex;
    padding: 5px;
    background-color: ${({ theme }) => theme.greyscale.grey5};
    border-radius: 4px;
`;

const MuiButton = styled(Button)`
    margin: 5px 10px
`;
MuiButton.displayName = "MuiButton";

const MuiSelect = styled(Select)`
    min-width: 15rem;
`;
MuiSelect.displayName = "MuiSelect";

const ImportOptions = ({ companyId, onOptionsImport }) => {
    const [selectedCompany, setSelectedCompany] = useState("");
    const { companies } = useCompanyContext();

    const onChange = ({ target: { value } }) => setSelectedCompany(value);

    return (
        <>
            <Typography variant="subtitle2">
                <Box fontWeight="fontWeightBold">Import Company Options</Box>
            </Typography>
            <ImportOptionsWrapper>
                <MuiSelect
                    value={selectedCompany}
                    onChange={onChange}
                    autoWidth
                >
                    {companies
                        .filter((x) => x.id !== companyId && Object.keys(x.companyOptions).length > 0)
                        .map((company) => (
                            <MenuItem key={company.id} value={company}>
                                {company.name}
                            </MenuItem>
                        ))}
                </MuiSelect>
                <MuiButton
                    disabled={!selectedCompany}
                    aria-label="confirm"
                    value="confirm"
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={() => onOptionsImport(selectedCompany.companyOptions)}
                >
                    confirm
                </MuiButton>
            </ImportOptionsWrapper>
        </>);
};

ImportOptions.propTypes = {
    companyId: PropTypes.string.isRequired,
    onOptionsImport: PropTypes.func.isRequired,
};

export default ImportOptions;
