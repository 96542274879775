import React, { useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { matchesTerm } from "utils/textHelpers";
import * as api from "utils/api";
import { sortByField } from "utils/arrayHelpers";
import { SectionTitle, SectionContent } from "components/Page/Section";
import Error from "components/Error";
import { Page, Section } from "components/Page";
import SearchBoxContainer from "components/SearchBox/SearchBoxContainer";
import EmptyList from "components/EmptyList";
import MediaAccountRow from "./MediaAccountRow/MediaAccountRow";
import * as mediaAccountListActions from "./mediaAccountListActions";
import { mediaAccountListReducer, mediaAccountListReducerInitializer } from "./mediaAccountListReducer";

const MediaAccountList = () => {
    const [mediaAccounts, setMediaAccounts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const { companyId } = useParams();

    React.useEffect(() => {
        setLoading(true);
        const loadCompanyMediaAccounts = async () => {
            try {
                const response = await api.get(`media-accounts/${companyId}`);
                setMediaAccounts(sortByField(response.data, "name"));
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        loadCompanyMediaAccounts().then(() => {
            setError(null);
            setLoading(false);
        });
    }, [companyId]);

    const [state, dispatch] = useReducer(mediaAccountListReducer,
        mediaAccounts,
        mediaAccountListReducerInitializer);

    const searchOptions = ({ target: { value } }) => dispatch(mediaAccountListActions.setSearchTerm(value));

    const allMediaAccounts = Object.entries(mediaAccounts);

    const filteredMediaAccounts = state.searchTerm
        ? allMediaAccounts
            .filter(([, value]) => matchesTerm(value.name, state.searchTerm)
                || matchesTerm(value.channel, state.searchTerm))
        : allMediaAccounts;

    const searchComponent = () => (
        <SectionContent>
            <SearchBoxContainer
                color="secondary"
                fullWidth
                type="search"
                onChange={searchOptions}
            />
            {filteredMediaAccounts.length
                ? (
                    <>
                        {filteredMediaAccounts.map(([key, value], index) => ((
                            <MediaAccountRow
                                key={`${index}-${key}`}
                                {...value}
                            />)))}
                    </>
                )
                : <EmptyList text="There are no accounts to display" />}
        </SectionContent>
    );

    return (
        <Page loading={loading} error={error}>
            <Section>
                <SectionTitle title="Accounts" />
                {error && <Error error={error} />}
                {!error && searchComponent()}
            </Section>
        </Page>
    );

};

export default MediaAccountList;
