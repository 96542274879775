import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import PropTypes from "prop-types";
import styled from "styled-components";

const ImportTable = styled.div`
    background-color: white;
    padding: 1rem;
    overflow-y: scroll;
`;
ImportTable.displayName = "ImportTable";

const UsersImportSummaryTable = ({ value }) => (
    <ImportTable>
        <Table size="small">
            <TableBody>
                <TableCell>
                    Number Of Users To Import
                </TableCell>
                <TableCell>
                    {value[0]}
                </TableCell>
            </TableBody>
        </Table>
    </ImportTable>);

UsersImportSummaryTable.defaultProps = {
    value: [],
};

UsersImportSummaryTable.propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
};

export default UsersImportSummaryTable;
