export const SET_SELECTED_FILE = "setSelectedFile";
export const SET_IMPORT_ID = "setImportId";
export const SET_IMPORT_DATA = "setImportData";
export const SET_IMPORT_SUMMARY_TABLE_TYPE = "setImportSummaryTableType";
export const SET_IMPORT_STATUS = "setImportStatus";
export const SET_STEP_TYPE = "setStepType";

export default {
    SET_SELECTED_FILE,
    SET_IMPORT_ID,
    SET_IMPORT_DATA,
    SET_IMPORT_SUMMARY_TABLE_TYPE,
    SET_IMPORT_STATUS,
    SET_STEP_TYPE,
};
