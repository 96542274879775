export const LOGGED_IN_USERS_LIMIT = "LOGGED_IN_USERS_LIMIT";
export const STARTER_TEMPLATE_ID = "da3de1ae-928a-42d7-aef0-75113812f9fd";
export const LOGGED_IN_USERS_MAX_LIMIT = 10000;
export const LOGGED_IN_USERS_MIN_LIMIT = 1;

export default {
    LOGGED_IN_USERS_LIMIT,
    STARTER_TEMPLATE_ID,
    LOGGED_IN_USERS_MAX_LIMIT,
    LOGGED_IN_USERS_MIN_LIMIT,
};
