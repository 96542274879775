import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Button, DialogActions, DialogContent, DialogTitle,
} from "@material-ui/core";
import * as api from "utils/api";
import LoadingSpinner from "components/LoadingSpinner";

const InternalEmailValidationDialog = ({
    submitMediaAccountUpdates, resetValuesWhenNotSaved,
    harvestingAddress, companyId, mediaAccountId,
}) => {

    const [duplicatedEmails, setDuplicatedEmails] = useState([]);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        setLoading(true);
        const validateMediaAccount = async () => {
            const conflictingEmailAddresses = await api.get(
                `media-accounts/internal-email/${companyId}/${mediaAccountId}/validate/${harvestingAddress}`
            );
            setDuplicatedEmails(conflictingEmailAddresses);
            setLoading(false);
        };
        validateMediaAccount();
    }, [mediaAccountId, companyId, harvestingAddress]);

    const duplicatedEmailList = () => {
        const entryItems = duplicatedEmails.map((entry, idx) => (<li key={idx}>{entry}</li>));

        return (
            <DialogContent>
                <span>Warning: Matching prefixes on the following accounts, are you sure you want to proceed?</span>
                {entryItems}
            </DialogContent>
        );
    };

    const confirmationContent = () => (
        <DialogContent>
            <span>Are you sure you want to update?</span>
        </DialogContent>
    );

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <DialogTitle>Update Media Account</DialogTitle>
            {duplicatedEmails.length > 0 ? duplicatedEmailList() : confirmationContent()}
            <DialogActions>
                <Button
                    onClick={submitMediaAccountUpdates}
                    color="primary"
                >
                    Submit
                </Button>
                <Button
                    onClick={resetValuesWhenNotSaved}
                    color="secondary"
                >
                    Cancel
                </Button>
            </DialogActions>
        </>);

};

InternalEmailValidationDialog.propTypes = {
    harvestingAddress: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    mediaAccountId: PropTypes.string.isRequired,
    submitMediaAccountUpdates: PropTypes.func.isRequired,
    resetValuesWhenNotSaved: PropTypes.func,
};

export default InternalEmailValidationDialog;
