import React from "react";
import PropTypes from "prop-types";
import { Typography as MuiTypography, Collapse } from "@material-ui/core";
import styled from "styled-components";

const MessageWrapper = styled.div`
    position: fixed;
    text-align: center;
    top: 10px;
    right: 10px;
    padding: 20px 40px;
    min-width: 400px;
    background-color: ${({ theme }) => theme.success};
    border-radius: 4px;
    opacity: 0.8;
`;

const Typography = styled(MuiTypography)`
    color: ${({ theme }) => theme.greyscale.white};
`;

const SuccessAlert = ({ message, show }) => (
    <Collapse in={show} timeout={300}>
        <MessageWrapper>
            <Typography variant="caption">{message}</Typography>
        </MessageWrapper>
    </Collapse>
);

SuccessAlert.propTypes = {
    message: PropTypes.string.isRequired,
    show: PropTypes.bool,
};

SuccessAlert.defaultProps = {
    show: false,
};

export default SuccessAlert;
