import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const SectionTabsContainer = styled(AppBar)`
    box-shadow: none;

    span {
        font-size: 1rem;
        text-transform: none;
        font-weight: bold;
    }

    &.MuiAppBar-colorPrimary {
        color: ${({ theme }) => theme.greyscale.black};
        background-color: ${({ theme }) => theme.greyscale.white};
    }

    button.Mui-selected {
        color: ${({ theme }) => theme.brand};
    }

    .MuiTabs-indicator {
        background-color: ${({ theme }) => theme.brand};
    }
`;

const SectionTabs = ({ tabs, activeTabIndex, onTabChange }) => (
    <SectionTabsContainer position="static">
        <Tabs value={activeTabIndex} onChange={onTabChange}>
            {tabs.map((tab) => (
                <Tab key={tab} label={tab} />
            ))}
        </Tabs>
    </SectionTabsContainer>
);

SectionTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeTabIndex: PropTypes.number,
    onTabChange: PropTypes.func.isRequired,
};

export default SectionTabs;
