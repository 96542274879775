/* eslint-disable react/jsx-indent-props */
import React, { useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "components/Page";
import {
    Section,
    SectionTitle,
    SectionContent,
    SectionFooter,
    SectionFooterButton,
} from "components/Page/Section";
import FieldsContainer from "components/Page/Containers/FieldsContainer";
import FileUploadContainer from "components/Page/Containers/FileUploadContainer";
import LoadingSpinner from "components/LoadingSpinner";
import { useCompanyContext } from "context/CompanyContext/CompanyContext";
import * as api from "utils/api";
import * as Constants from "../ImportConstants";
import ImportDetailsTypeMap from "./ImportDetailsTypeMap";
import ImportSummary from "../ImportSummaries/ImportSummary";
import {
    importDetailsReducer, importDetailsReducerInitialState,
} from "./importDetailsReducer";
import * as actionType from "./importDetailsActions";

export const ImportDetails = () => {
    const SummaryOnConfirmTypes = [Constants.USERS];
    const SummaryOnUploadTypes = [Constants.DYNAMIC_DATA, Constants.QUEUES, Constants.SERVICE_LEVEL];
    const defaultImportStatus = {
        isUploading: false,
        message: "",
        hasError: false,
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [state, dispatch] = useReducer(importDetailsReducer, importDetailsReducerInitialState);
    const { error, loading } = useCompanyContext();
    const { type, companyId } = useParams();

    const {
        title, subTitle, acceptedFileType, blobType, label, dataProperty, importUrl, confirmDataProperty,
    } = ImportDetailsTypeMap[type] || ImportDetailsTypeMap.unknown;

    const fileIsValid = selectedFile && selectedFile.name.endsWith(acceptedFileType);
    const onUpload = async () => {
        dispatch(actionType.resetImportData());
        try {
            dispatch(actionType.setImportStatus(defaultImportStatus));
            const blob = new Blob([selectedFile], { type: blobType });
            const formData = new FormData();
            formData.append("file", blob, selectedFile.name);
            formData.append("companyId", companyId);

            const response = await api.postFile(importUrl, formData);
            dispatch(actionType.setImportId(response.id));

            if (SummaryOnConfirmTypes.includes(type)) {
                dispatch(actionType.setImportData({
                    data: [response[dataProperty]],
                    summaryType: Constants.UPLOAD,
                }));
            }
            if (SummaryOnUploadTypes.includes(type)) {
                dispatch(actionType.setImportData({
                    data: response[dataProperty],
                    summaryType: Constants.UPLOAD,
                }));
            }

            dispatch(actionType.setImportStatus(defaultImportStatus));
            dispatch(actionType.setStepType(Constants.CONFIRM));
        } catch (err) {
            dispatch(actionType.setImportStatus({
                isUploading: false,
                message: err.userMessage || "Server Error",
                hasError: true,
            }));
        }
    };

    const onConfirm = async () => {
        dispatch(actionType.resetImportData());
        try {
            dispatch(actionType.setImportStatus({
                isUploading: true,
                message: Constants.AWAITING_CONFIRM,
                hasError: false,
            }));
            const url = `${importUrl}/${state.importId}`;
            const response = await api.put(url, { companyId });

            dispatch(actionType.setStepType(Constants.UPLOAD));
            dispatch(actionType.setImportStatus({
                isUploading: false,
                message: Constants.UPLOAD_COMPLETE,
                hasError: false,
            }));

            if (SummaryOnConfirmTypes.includes(type)) {
                dispatch(actionType.setImportData({
                    data: response[confirmDataProperty],
                    summaryType: Constants.CONFIRM,
                }));
            }
        } catch (err) {
            dispatch(actionType.setImportStatus({
                isUploading: false,
                message: err.userMessage || Constants.UPLOAD_ERROR,
                hasError: true,
            }));
        }
    };

    const onCancel = () => {
        dispatch(actionType.setImportStatus({
            isUploading: false,
            message: "",
            hasError: false,
        }));
        dispatch(actionType.setStepType(Constants.UPLOAD));
    };

    return (
        <Page loading={loading} error={error}>
            <Section>
                <SectionTitle title={title} hideBack={false} />
                <SectionContent>
                    <p>{subTitle}</p>
                    <FieldsContainer maxHeight="initial">
                        <FileUploadContainer
                            label={label}
                            onFileSelect={(file) => setSelectedFile(file)}
                            selectedFilename={selectedFile && selectedFile.name}
                            valid={fileIsValid}
                            disabled={state.stepType === Constants.CONFIRM}
                            acceptedFileType={acceptedFileType}
                        />
                        <ImportSummary
                            importType={type}
                            collectionValues={state.importData.data}
                            message={state.importStatus.message}
                            error={state.importStatus.hasError}
                            importSummaryType={state.importData.summaryType}
                        />
                        {state.importStatus.isUploading && <LoadingSpinner height="80px" width="400px" />}
                    </FieldsContainer>
                </SectionContent>
                <SectionFooter>
                    {/* eslint-disable-next-line operator-linebreak */ }
                    {state.stepType === Constants.CONFIRM ?
                        <div>
                            <SectionFooterButton
                                disabled={!selectedFile || !fileIsValid}
                                onClick={onConfirm}
                                buttonText="CONFIRM"
                            />
                            <SectionFooterButton
                                disabled={!selectedFile || !fileIsValid}
                                onClick={onCancel}
                                color="secondary"
                                variant="text"
                                buttonText="CANCEL"
                            />
                        </div>
                        : <SectionFooterButton
                            disabled={!selectedFile || !fileIsValid || state.importStatus.isUploading}
                            onClick={onUpload}
                            buttonText="UPLOAD"
                        />}
                </SectionFooter>
            </Section>
        </Page>
    );
};

export default ImportDetails;
