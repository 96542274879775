import PropTypes from "prop-types";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import { Typography } from "@material-ui/core";
import React from "react";

const DialogTitle = styled(AppBar)`
    text-align: center;
    padding: 10px;
`;

const ActionDialogTitle = ({ title }) => (
    <DialogTitle position="static" color="secondary">
        <Typography variant="h5">{title}</Typography>
    </DialogTitle>
);

ActionDialogTitle.propTypes = {
    title: PropTypes.string,
};

export default ActionDialogTitle;
