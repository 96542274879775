import React from "react";
import { Dialog } from "@material-ui/core";
import PropTypes from "prop-types";
import * as api from "utils/api";
import DefaultDialog from "../DialogContent/defaultDialog";
import InternalEmailValidationDialog from "../DialogContent/internalEmailValidationDialog";

const UpdateMediaAccountDialog = ({
    open, handleClose, companyId, mediaAccountId,
    channel, name, harvestingAddress, notifySuccess, internalCheck,
}) => {

    const submitMediaAccountUpdates = async () => {
        try {
            if (harvestingAddress === "") {
                notifySuccess(false, { errors: { HarvestingAddress: ["An email address must be entered"] } });
            }
            const requestBody = {
                mediaAccountId,
                name,
                channel,
            };
            if (harvestingAddress) {
                requestBody.type = "internal";
                requestBody.harvestingAddress = harvestingAddress;
            }

            await api.put(`media-accounts/${companyId}/update`, requestBody);
            handleClose();
            notifySuccess(true);
        } catch (err) {
            handleClose();
            notifySuccess(false, err.errors !== undefined ? err : {
                errors:
                    {
                        HarvestingAddress: ["Sorry, an error occurred."
                        + " Please ensure that the domain on this email address is listed as an alias domain"],
                    },
            });
        }
    };

    const resetValuesWhenNotSaved = () => {
        handleClose();
    };

    return (
        <Dialog open={open} onClose={resetValuesWhenNotSaved} fullWidth>
            {!internalCheck && <DefaultDialog
                submitMediaAccountUpdates={submitMediaAccountUpdates}
                resetValuesWhenNotSaved={resetValuesWhenNotSaved}
            />}
            {internalCheck && <InternalEmailValidationDialog
                submitMediaAccountUpdates={submitMediaAccountUpdates}
                resetValuesWhenNotSaved={resetValuesWhenNotSaved}
                mediaAccountId={mediaAccountId}
                companyId={companyId}
                harvestingAddress={harvestingAddress}
            />}
        </Dialog>
    );
};

UpdateMediaAccountDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
    mediaAccountId: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    harvestingAddress: PropTypes.string,
    internalCheck: PropTypes.bool,
};

UpdateMediaAccountDialog.defaultProps = {
    internalCheck: false,
};

export default UpdateMediaAccountDialog;
