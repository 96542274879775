export const objectsAreDifferent = (original, changed) => {
    const originalKeys = Object.keys(original);
    const changedKeys = Object.keys(changed);

    const keysRemoved = originalKeys.some((key) => !changedKeys.includes(key));
    const keysAdded = changedKeys.some((key) => !originalKeys.includes(key));
    const valuesChanged = originalKeys.some((key) => changed[key] !== original[key]);
    const indexesChanged = originalKeys.some((key, index) => index !== changedKeys.indexOf(key));

    return keysAdded || keysRemoved || valuesChanged || indexesChanged;
};

export default { objectsAreDifferent };
