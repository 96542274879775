import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Error from "components/Error";
import LoadingSpinner from "components/LoadingSpinner";

export const PageElement = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: ${({ theme }) => theme.greyscale.white};
    border: solid 1px ${({ theme }) => theme.greyscale.grey4};
    box-sizing: border-box;
    overflow-y: scroll;
`;

const Page = ({ loading, error, children }) => {
    if (error) {
        return (
            <PageElement>
                <Error error={error} />
            </PageElement>
        );
    }

    if (loading) {
        return (
            <PageElement>
                <LoadingSpinner />
            </PageElement>
        );
    }

    return (
        <PageElement>
            {children}
        </PageElement>
    );
};

Page.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Page;
