import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import PropTypes from "prop-types";
import styled from "styled-components";

const ImportTable = styled.div`
    background-color: white;
    padding: 1rem;
    max-height: 68vh;
    overflow-y: scroll;
`;

ImportTable.displayName = "ImportTable";

const GenericSummaryTable = ({ collectionValues }) => {
    const tableHeader = [];
    const rowContent = [];
    const equalDivision = (100 / Object.keys(collectionValues[0]).length);

    const getHumanReadableHeader = (jsonKey) => jsonKey.replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());

    Object.keys(collectionValues[0]).map((key) => tableHeader.push(
        <TableCell style={{ width: `${equalDivision}%` }}>
            {getHumanReadableHeader(key)}
        </TableCell>
    ));

    collectionValues.map((entry) => {
        const rowData = [];
        Object.values(entry).map((value) => (rowData.push(
            <TableCell style={{ width: `${equalDivision}%` }}>{value}</TableCell>
        )));
        return rowContent.push(<TableRow>{rowData}</TableRow>);
    });

    return (
        <ImportTable>
            <Table size="small">
                <TableHead>
                    <TableRow key="header">
                        {tableHeader}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowContent}
                </TableBody>
            </Table>
        </ImportTable>);
};

GenericSummaryTable.defaultProps = {
    collectionValues: [],
};

GenericSummaryTable.propTypes = {
    collectionValues: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({})),
        PropTypes.shape({}),
    ]),
};

export default GenericSummaryTable;
