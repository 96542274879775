import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import apple from "assets/channels/apple.svg";
import chat from "assets/channels/chat.svg";
import email from "assets/channels/email.svg";
import facebook from "assets/channels/facebook.svg";
import instagram from "assets/channels/instagram.svg";
import messenger from "assets/channels/messenger.svg";
import phone from "assets/channels/phone.svg";
import trustpilot from "assets/channels/trustpilot.svg";
import twitter from "assets/channels/twitter.svg";
import vk from "assets/channels/VK.svg";
import voice from "assets/channels/voice.svg";
import whatsapp from "assets/channels/whatsapp.svg";

const getIcon = (channel) => {
    switch (channel.toLowerCase()) {
        case "applebusinesschat":
            return apple;
        case "webchat":
            return chat;
        case "email":
            return email;
        case "facebook":
            return facebook;
        case "instagram":
            return instagram;
        case "messenger":
            return messenger;
        case "trustpilot":
            return trustpilot;
        case "phone":
            return phone;
        case "twitter":
            return twitter;
        case "vk":
            return vk;
        case "voice":
            return voice;
        case "whatsapp":
            return whatsapp;
        default:
            return null;
    }
};

const ChannelImage = styled.img`
    width: 100%;
`;

const ChannelIcon = ({ channel }) => (
    <Avatar>
        <ChannelImage src={getIcon(channel)} alt="No items" />
    </Avatar>
);

ChannelIcon.propTypes = {
    channel: PropTypes.string,
};

export default ChannelIcon;
