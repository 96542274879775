export const accordionSummary = {
    flex: 1,
};

export const accordionWidth = {
    width: "100%",
    maxHeight: "68vh",
    overflowY: "auto",
};

export default { accordionSummary, accordionWidth };
