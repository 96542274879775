import React from "react";
import { Route, Switch } from "react-router-dom";
import { CompanyContextProvider } from "context/CompanyContext/CompanyContext";
import MainMenu from "domain/MainMenu";
import CompanyMenu from "domain/Company/CompanyMenu";
import CompanyPage from "domain/Company/CompanyPage";
import { AddCompany, EditCompany } from "domain/Company/CompanyDetails";
import Subscription from "domain/Company/Subscription";
import { ImportList, ImportDetails, ImportSelection } from "domain/Company/ConfigurationImport";
import { MediaAccountList, MediaAccountDetails } from "domain/Company/MediaAccounts";
import { Users, UserDetails } from "domain/Company/Users";
import License from "domain/Company/Licence";
import {
    COMPANY,
    COMPANY_DETAILS,
    COMPANY_SUBSCRIPTION,
    COMPANY_LICENSE,
    ACCOUNTS,
    CONFIGURATION_IMPORT,
    USERS,
} from "./RouterConstants";

const newCompany = `/${COMPANY}/new`;
const existingCompany = `/${COMPANY}/:companyId`;

export const CompanyRouter = () => (
    <CompanyContextProvider>
        <MainMenu />
        <Switch>
            <Route path={`${newCompany}/${COMPANY_DETAILS}`}>
                <AddCompany />
            </Route>
            <Route path={existingCompany}>
                <CompanyPage>
                    <CompanyMenu />
                    <Switch>
                        <Route path={`${existingCompany}/${COMPANY_DETAILS}`}>
                            <EditCompany />
                        </Route>
                        <Route path={`${existingCompany}/${COMPANY_SUBSCRIPTION}`}>
                            <Subscription />
                        </Route>
                        <Route path={`${existingCompany}/${COMPANY_LICENSE}`}>
                            <License />
                        </Route>
                        <Route exact path={`/${CONFIGURATION_IMPORT}`}>
                            <ImportList />
                        </Route>
                        <Route path={`/${CONFIGURATION_IMPORT}/:type`}>
                            <ImportList />
                            <ImportDetails />
                        </Route>
                        <Route exact path={`${existingCompany}/${CONFIGURATION_IMPORT}`}>
                            <ImportSelection />
                        </Route>
                        <Route path={`${existingCompany}/${CONFIGURATION_IMPORT}/:type`}>
                            <ImportDetails />
                        </Route>
                        <Route path={`${existingCompany}/${ACCOUNTS}/:mediaAccountId`}>
                            <MediaAccountDetails />
                        </Route>
                        <Route path={`${existingCompany}/${ACCOUNTS}`}>
                            <MediaAccountList />
                        </Route>
                        <Route path={`${existingCompany}/${USERS}/:userId`}>
                            <UserDetails />
                        </Route>
                        <Route path={`${existingCompany}/${USERS}`}>
                            <Users />
                        </Route>
                    </Switch>
                </CompanyPage>
            </Route>
        </Switch>
    </CompanyContextProvider>
);

export default CompanyRouter;
