import * as constants from "./userListConstants";

export const userListReducerInitializer = (initialUsers) => ({
    users: initialUsers,
    searchTerm: null,
});

export const userListReducer = (state, action) => {
    if (!action) return state;

    switch (action.type) {
        case constants.SEARCH_USERS: {
            return { ...state, searchTerm: action.payload.searchTerm };
        }
        default:
            return state;
    }
};
