import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const SectionFooterElement = styled.div`
    display: flex;
    padding: 20px 10px 10px;
    gap: 10px;
    align-items: center;
    justify-content: center;
`;

const SectionFooter = ({ children }) => (
    <SectionFooterElement>
        {children}
    </SectionFooterElement>
);

SectionFooter.propTypes = {
    children: PropTypes.node,
};

export default SectionFooter;
