import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, MenuItem } from "@material-ui/core";
import Select from "components/MaterialUi/Select";
import Error from "components/Error";
import { useCompanyContext } from "context/CompanyContext/CompanyContext";
import { useAuthContext } from "authorization/AuthContext";
import {
    SectionTitle,
    SectionContent,
    SectionFooter,
    SectionFooterButton,
} from "components/Page/Section";
import SuccessAlert from "components/Alerts";
import Modal from "components/Modal";
import { post, put } from "utils/api";
import ButtonGroup from "components/MaterialUi/ButtonGroup";
import FieldsContainer from "components/Page/Containers/FieldsContainer";
import TextField from "components/MaterialUi/TextField";
import Label from "components/MaterialUi/Label";
import SuspensionMessage from "./SuspensionMessage";

TextField.displayName = "DetailsSectionTextField";

export const DetailsSection = ({ newCompany, company: initialCompanyValues, onHeaderUpdate }) => {
    const history = useHistory();
    const { licensingTiers } = useAuthContext();
    const { reloadCompanies } = useCompanyContext();
    const [company, setCompany] = useState(initialCompanyValues);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        setError(null);
    }, [history.location.key]);

    const [formErrors, setFormErrors] = useState({});
    const [modalOpen, toggleModalOpen] = useState(false);

    const handleCompanyNameChange = ({ value }) => {
        onHeaderUpdate(value);
        setCompany((prevState) => ({ ...prevState, name: value }));
        setUnsavedChanges(true);
    };

    const handleChange = ({ value }, field) => {
        setCompany((prevState) => ({ ...prevState, [field]: value }));
        setUnsavedChanges(true);
    };

    const handleSaveSuccess = async () => {
        setSaveSuccessful(true);
        setTimeout(() => setSaveSuccessful(false), 5000);
        setSaving(false);
        setUnsavedChanges(false);
        onHeaderUpdate(company.name);
        setFormErrors({});
        await reloadCompanies();
    };

    const handleAddSuccess = async (id) => {
        setSaveSuccessful(true);
        history.push(`/company/${id}/details`);
        await reloadCompanies();
    };

    const enableSaveButton = () => {
        if (saving) {
            return false;
        }

        if (!unsavedChanges) {
            return false;
        }

        const {
            domain,
            name,
        } = company;

        return domain && name;
    };

    const submitChanges = async () => {
        toggleModalOpen(false);
        setSaving(true);
        const {
            domain,
            name, website,
            enabled,
            id,
            licenseTemplateId,
        } = company;

        try {
            if (newCompany) {
                const response = await post(
                    "company",
                    {
                        name, domain, website, licenseTemplateId,
                    }
                );
                handleAddSuccess(response.data);
            } else {
                await put(`company/${id}`, {
                    name,
                    domain,
                    website,
                    enabled,
                });
                handleSaveSuccess();
            }
        } catch (err) {
            const { status, errors, userMessage } = err;
            if (status === 400) {
                setFormErrors(errors);
            } else if (userMessage) {
                setError(userMessage);
            } else {
                setError(err.message);
            }
            setSaving(false);
        }
    };

    if (error) {
        return <Error error={error} />;
    }

    return (
        <>
            <Modal
                open={modalOpen}
                text="Are you sure you want to save?"
                onConfirm={submitChanges}
                onClose={() => toggleModalOpen(false)}
            />
            <SectionTitle title="Company Details" />
            {company.suspension && <SuspensionMessage date={company.suspension.suspendAt} />}
            <SectionContent>
                <FieldsContainer>
                    <TextField
                        label="Name"
                        size="small"
                        error={!!formErrors.Name}
                        defaultValue={company.name}
                        color="secondary"
                        onChange={({ target }) => handleCompanyNameChange(target)}
                        helperText={formErrors.Name ? formErrors.Name[0] : null}
                        id="company-name"
                    />
                    <TextField
                        label="Domain"
                        size="small"
                        error={!!formErrors.Domain}
                        defaultValue={company.domain}
                        color="secondary"
                        onChange={({ target }) => handleChange(target, "domain")}
                        disabled={newCompany === false}
                        helperText={formErrors.Domain ? formErrors.Domain[0] : null}
                        id="company-domain"
                    />
                    <TextField
                        label="Website"
                        size="small"
                        error={!!formErrors.Website}
                        defaultValue={company.website}
                        color="secondary"
                        onChange={({ target }) => handleChange(target, "website")}
                        helperText={formErrors.Website ? formErrors.Website[0] : null}
                        id="company-website"
                    />
                    {/* eslint-disable-next-line operator-linebreak */}
                    {!newCompany &&
                        <>
                            <Label>Enabled</Label>
                            <ButtonGroup datagnatta="enabled">
                                <Button
                                    aria-label="enabled"
                                    value="enabled"
                                    color={company.enabled ? "secondary" : "default"}
                                    variant="contained"
                                    size="small"
                                    onClick={() => handleChange({ value: true }, "enabled")}
                                >
                                    enabled
                                </Button>
                                <Button
                                    aria-label="disabled"
                                    value="disabled"
                                    color={!company.enabled ? "secondary" : "default"}
                                    variant="contained"
                                    size="small"
                                    onClick={() => handleChange({ value: false }, "enabled")}
                                >
                                    disabled
                                </Button>
                            </ButtonGroup>
                        </>}
                    {/* eslint-disable-next-line operator-linebreak */}
                    {newCompany &&
                        <>
                            <Label id="company-license-selection-label">License</Label>
                            <Select
                                id="company-license-selection"
                                labelId="company-license-selection-label"
                                value={company.licenseTemplateId}
                                onChange={({ target }) => handleChange(target, "licenseTemplateId")}
                            >
                                {licensingTiers.map((tier) => (
                                    <MenuItem value={tier.templateId} key={tier.templateId}>
                                        {tier.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>}
                </FieldsContainer>
                <SuccessAlert message="Company details saved" show={saveSuccessful} />
            </SectionContent>
            <SectionFooter>
                <SectionFooterButton
                    onClick={() => toggleModalOpen(true)}
                    disabled={!enableSaveButton()}
                    buttonText="SAVE"
                />
            </SectionFooter>
        </>
    );
};

DetailsSection.propTypes = {
    newCompany: PropTypes.bool,
    company: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        domain: PropTypes.string,
        website: PropTypes.string,
        enabled: PropTypes.bool,
        suspension: PropTypes.shape({
            suspendAt: PropTypes.string,
        }),
        licenseTemplateId: PropTypes.string,
    }),
    onHeaderUpdate: PropTypes.func,
};

DetailsSection.defaultProps = {
    newCompany: false,
    company: {
        id: "",
        name: "",
        domain: "",
        website: "",
        enabled: true,
        licenseTemplateId: "d78a2339-bb40-4748-bc4e-cf5e8a012eb2",
    },
    onHeaderUpdate: () => { },
};

export default DetailsSection;
