import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

const ColourCircle = styled.div`
    border: 1px solid ${(props) => props.theme.greyscale.grey2};
    background: ${(props) => props.colour};
    height: 1rem;
    border-radius: 50%;
    width: 1rem;
`;
ColourCircle.displayName = "ColourCircle";

const HexColourIcon = ({ colour }) => (<ColourCircle colour={colour} />);

HexColourIcon.propTypes = {
    colour: PropTypes.string.isRequired,
};
export default HexColourIcon;
