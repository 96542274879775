import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import * as api from "utils/api";

const CompanyContext = React.createContext({});

export const CompanyContextProvider = ({ children }) => {
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadCompanies = async () => {
        try {
            const response = await api.get("company");
            setCompanies(response.data);
            setLoading(false);
        } catch (err) {
            setError(err.message);
        }
    };

    React.useEffect(() => {
        loadCompanies();
    }, []);

    const reloadCompanies = async () => {
        await loadCompanies();
    };

    const context = {
        companies,
        error,
        loading,
        reloadCompanies,
    };

    return (
        <CompanyContext.Provider value={context}>
            {children}
        </CompanyContext.Provider>
    );
};

CompanyContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useCompanyContext = () => useContext(CompanyContext);

export default CompanyContext;
