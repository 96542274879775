import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import loadingSpinner from "assets/logo/loading-spinner.gif";

const Img = styled.img`
    height: 100%;
`;

const LoadingSpinnerContainer = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoadingSpinner = (props) => (
    <LoadingSpinnerContainer {...props}>
        <Img src={loadingSpinner} alt="loading..." />
    </LoadingSpinnerContainer>
);

LoadingSpinner.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
};

LoadingSpinner.defaultProps = {
    height: "300px",
    width: "100%",
};

export default LoadingSpinner;
