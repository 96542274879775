import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { formatDate } from "utils/dateHelpers";
import { sortByField, sortAlphabetically } from "utils/arrayHelpers";
import Error from "components/Error";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const InviteAvatar = styled(ListItemAvatar)`
    &.active .MuiAvatar-colorDefault {
        background-color: ${({ theme }) => theme.brand};
    }
`;

const ProblemIcon = styled(CancelIcon)`
    color: ${({ theme }) => theme.error};
    margin-top: -14px;
    margin-left: 26px;
    position: absolute;
`;

const InvitesList = ({ loading, invites, error }) => {
    if (loading) {
        return <LoadingSpinner height="150px" />;
    }

    if (error) {
        return <Error error={error} />;
    }

    if (!invites || !invites.length) {
        return <span>There are no invites to show</span>;
    }

    const getIcon = (status) => {
        switch (status) {
            case "Pending":
                return <HourglassEmptyIcon />;
            case "Expired":
                return <ScheduleIcon />;
            default:
                return <MailOutlineIcon />;
        }
    };

    return sortByField(invites, "created")
        .map((invite) => {
            const roles = [
                "USER",
                ...invite.roles,
            ];

            const status = invite.expired ? "Expired" : invite.status;
            const createdDateText = formatDate(invite.created, "DD/MM/YYYY");
            const expiredText = !invite.expired ? `, Expires on ${formatDate(invite.expiry, "DD/MM/YYYY")}` : "";
            const rolesText = sortAlphabetically(roles).join(", ").toLowerCase();

            return (
                <ListItem key={invite.id} divider>
                    <InviteAvatar className={status === "Sent" && "active"}>
                        <>
                            <Avatar>
                                {getIcon(status)}
                            </Avatar>
                            {(invite.status === "Suppressed" || invite.status === "Failed")
                                && <ProblemIcon />}
                        </>
                    </InviteAvatar>
                    <ListItemText
                        primary={invite.email}
                        secondary={`${status}, Created on ${createdDateText}${expiredText}, Roles: ${rolesText}`}
                    />
                </ListItem>
            );
        });
};

InvitesList.propTypes = {
    loading: PropTypes.bool,
    invites: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.string),
        created: PropTypes.string,
        expiry: PropTypes.string,
        expired: PropTypes.bool,
    })).isRequired,
    error: PropTypes.string,
};

InvitesList.defaultProps = {
    loading: true,
    error: "",
};

export default InvitesList;
