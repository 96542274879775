import React from "react";
import PropTypes from "prop-types";
import {
    DYNAMIC_DATA,
    SERVICE_LEVEL,
    HEXADECIMAL_REGEX,
    UPLOAD,
    USERS, QUEUES, CONFIRM,
} from "../../../ImportConstants";
import DynamicDataImportSummaryTable from "./DynamicDataImportSummaryTable";
import UsersImportSummaryTable from "./UsersImportSummaryTable";
import GenericSummaryTable from "./GenericSummaryTable";
import HexColourIcon from "../../ColourCircle";

function generateColourIcons(collectionValues) {
    collectionValues.forEach((entry) => {
        Object.entries(entry).forEach((value) => {
            if (HEXADECIMAL_REGEX.test(Object(value)[1])) {
                entry[value[0]] = <HexColourIcon colour={value[1]} />;
            }
        });
    });
}

const RenderTableFactory = ({ importType, collectionValues, importSummaryType }) => {
    if (importSummaryType === UPLOAD) {
        switch (importType) {
            case USERS:
                return <UsersImportSummaryTable
                    value={collectionValues}
                />;
            case DYNAMIC_DATA:
                return <DynamicDataImportSummaryTable
                    collectionValues={collectionValues}
                />;
            default:
                return <GenericSummaryTable
                    collectionValues={collectionValues}
                />;
        }
    }

    switch (importType) {
        case DYNAMIC_DATA:
            return <DynamicDataImportSummaryTable
                collectionValues={collectionValues}
            />;
        case SERVICE_LEVEL:
            generateColourIcons(collectionValues);
            return <GenericSummaryTable
                collectionValues={collectionValues}
            />;
        default:
            return <GenericSummaryTable
                collectionValues={collectionValues}
            />;
    }
};

RenderTableFactory.propTypes = {
    importType: PropTypes.oneOf([USERS, DYNAMIC_DATA, SERVICE_LEVEL, QUEUES]).isRequired,
    collectionValues: PropTypes.oneOfType(
        [
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.arrayOf(PropTypes.number),
        ]
    ).isRequired,
    importSummaryType: PropTypes.oneOf([UPLOAD, CONFIRM]).isRequired,
};

export default RenderTableFactory;
