import { createMuiTheme } from "@material-ui/core/styles";

const gnattaBrand = {
    brandPurple: "#9746B7",
    brandBlue: "#00c6f8",
    brandGrey: "#333953",
    brandRed: "#ed0f67",
    brandOrange: "#ff913d",
    brandGreen: "#b1d234",
    button01: "#3caf88",
    background: "#f7f0fa",

    primary: {
        light: "#3caf88;",
        main: "#3caf88",
        dark: "#3caf88",
    },
    secondary: {
        light: "#52C7B8;",
        main: "#009688",
        dark: "#00675B",
    },
    greyscale: {
        black: "#000000",
        grey1: "#b2b6c2",
        grey2: "#c1c4ce",
        grey3: "#d1d3da",
        grey4: "#e0e2e7",
        grey5: "#f0f0f3",
        grey6: "#f7f7f8",
        white: "#FFFFFF",
    },
    error: "#EB5757",
    warn: "#F2C94C",
    success: "#27AE60",
    info: "#2D9CDB",
};

export const gnattaTheme = {
    brand: gnattaBrand.brandPurple,
    background: gnattaBrand.background,
    primaryControl: gnattaBrand.primary,
    secondaryControl: gnattaBrand.secondary,
    greyscale: gnattaBrand.greyscale,
    error: gnattaBrand.brandRed,
    warning: gnattaBrand.brandOrange,
    success: gnattaBrand.brandGreen,
    info: gnattaBrand.brandBlue,
};

export const gnattaMuiTheme = createMuiTheme({
    palette: {
        primary: gnattaBrand.primary,
        secondary: gnattaBrand.secondary,
        error: {
            main: gnattaBrand.brandRed,
        },
        warning: {
            main: gnattaBrand.brandOrange,
        },
        info: {
            main: gnattaBrand.brandBlue,
        },
        success: {
            main: gnattaBrand.brandGreen,
        },
    },
});

export default gnattaTheme;
