import FilterListIcon from "@material-ui/icons/FilterList";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import SdCardIcon from "@material-ui/icons/SdCard";
import StorageOutlined from "@material-ui/icons/StorageOutlined";
import * as Constants from "../ImportConstants";

const dynamicData = {
    text: "Dynamic Data Types",
    helperText: "Import Dynamic Data Types, their options and conditions to a domain",
    type: Constants.DYNAMIC_DATA,
    icon: SdCardIcon,
    disabled: false,
};

const users = {
    text: "Users",
    helperText: "Import Users to a domain",
    type: Constants.USERS,
    icon: PeopleAltIcon,
    disabled: false,
};

const queues = {
    text: "Queues",
    helperText: "Import Queues to a domain",
    type: Constants.QUEUES,
    icon: FilterListIcon,
    disabled: false,
};

const serviceLevels = {
    text: "Service Levels",
    helperText: "Import Service Levels to a domain",
    type: Constants.SERVICE_LEVEL,
    icon: StorageOutlined,
    disabled: false,
};

export default [
    dynamicData,
    users,
    queues,
    serviceLevels,
];
