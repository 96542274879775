import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import MuiButton from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const Button = styled(MuiButton)`
    padding: 10px 40px;
    color: ${({ theme }) => theme.greyscale.white}
`;

const SectionFooterButton = ({
    onClick,
    disabled,
    buttonText,
    color,
    variant,
}) => (
    <Button
        id={`${buttonText.toLowerCase()}-button`}
        size="large"
        onClick={onClick}
        color={color}
        variant={variant}
        disabled={disabled}
    >
        <Typography variant="caption">{buttonText}</Typography>
    </Button>
);

SectionFooterButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    buttonText: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
};

SectionFooterButton.defaultProps = {
    onClick: () => null,
    disabled: true,
    buttonText: "",
    color: "primary",
    variant: "contained",
};

export default SectionFooterButton;
