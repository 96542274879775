import styled from "styled-components";

export const FieldsContainer = styled.div`    
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 22vh;
    max-height: ${(props) => props.maxHeight || "40vh"};
`;

export default FieldsContainer;
