import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SectionContentElement = styled.div`
    flex: 1 1 auto;
`;

const SectionContent = ({ children }) => (
    <SectionContentElement>
        {children}
    </SectionContentElement>
);

SectionContent.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SectionContent;
