import React from "react";
import PropTypes from "prop-types";
import ActionDialog from "components/ActionDialog";
import AliasDomainDialogContent from "./AliasDomainDialogContent/AliasDomainDialogContent";

const NewAliasDomainDialog = ({
    open, onClose, companyId, domains,
}) => (
    <ActionDialog
        title="Add New Alias Domain"
        onClose={onClose}
        open={open}
    >
        <AliasDomainDialogContent
            companyId={companyId}
            onClose={onClose}
            domains={domains}
        />
    </ActionDialog>
);

NewAliasDomainDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    companyId: PropTypes.string,
    domains: PropTypes.arrayOf(PropTypes.string),
};

export default NewAliasDomainDialog;
