import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import MuiButton from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { put, get } from "utils/api";
import {
    Page,
    Section,
    SectionContent,
    SectionFooter,
    SectionFooterButton,
    SectionTitle,
} from "components/Page";
import FieldsContainer from "components/Page/Containers/FieldsContainer";
import Modal from "components/Modal";
import TextField from "components/MaterialUi/TextField";
import SuccessAlert from "components/Alerts";
import {
    LOGGED_IN_USERS_LIMIT,
    STARTER_TEMPLATE_ID,
    LOGGED_IN_USERS_MAX_LIMIT,
    LOGGED_IN_USERS_MIN_LIMIT,
} from "./LicenseContants";

const REASON_MAX_LENGTH = 255;
const SNACKBAR_TIMEOUT = 5000;

const Button = styled(MuiButton)`
    padding: 10px 40px;
    color: ${({ theme }) => theme.greyscale.white};
    width: 25rem;
    margin: 10px 0;
`;

const License = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [reason, setReason] = useState("");
    const [limit, setLimit] = useState(null);
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const { companyId } = useParams();

    /**
     * Loads the company license and checks if they have a starter tier. Shows error if they do.
     * Gets the LOGGED_IN_USERS_LIMIT from the response and sets the limit to that value or defaults to 10000.
     */
    const loadCompanyLicense = useCallback(async () => {
        try {
            const { data } = await get(`company/${companyId}/license`);

            // Can only have 1 Tier on a CompanyLicense so search by Starter TemplateId.
            const isStarter = data.templates.find((x) => x.templateId === STARTER_TEMPLATE_ID);

            if (isStarter) {
                setError("Cannot change license counts when company is on starter tier.");
                return;
            }

            const loggedInUserLimit = data.items.find((x) => x.key === LOGGED_IN_USERS_LIMIT);
            setLimit(loggedInUserLimit
                ? (loggedInUserLimit.limit || LOGGED_IN_USERS_MAX_LIMIT)
                : LOGGED_IN_USERS_MAX_LIMIT);
            setError(null);
        } catch (err) {
            setError(err.userMessage || err.technicalMessage || err.message);
        } finally {
            setLoading(false);
        }
    }, [companyId]);

    /**
     * Updates the license count for the company license. Shows success snackbar on success
     * Clears saving, modal and reason on success or failure
     */
    const saveLicenseCount = useCallback(async () => {
        try {
            setSaving(true);
            setModalOpen(false);
            const payload = {
                count: limit,
                reason,
            };
            await put(`company/${companyId}/license-count`, payload);
            setSnackbarVisible(true);
        } catch (err) {
            setError(err.userMessage || err.technicalMessage || err.message);
        } finally {
            setSaving(false);
            setReason("");
        }
    }, [companyId, limit, reason]);

    React.useEffect(() => {
        setLoading(true);
        loadCompanyLicense();
    }, [companyId, loadCompanyLicense]);

    React.useEffect(() => {
        if (snackbarVisible) {
            setTimeout(() => {
                setSnackbarVisible(false);
            }, SNACKBAR_TIMEOUT);
        }
    }, [snackbarVisible]);

    /**
     * Validate the limit value is within its boundaries.
     */
    const isOutsideOfLimit = () => limit < LOGGED_IN_USERS_MIN_LIMIT || limit > LOGGED_IN_USERS_MAX_LIMIT;

    /**
     * Criteria:
     * No reason
     * Saving
     * Limit out of bounds
     */
    const isSaveDisabled = () => {
        if (!reason.length) {
            return true;
        }

        if (saving) {
            return true;
        }

        return isOutsideOfLimit();
    };

    const onReasonChange = (event) => {
        setReason(event.target.value);
    };

    const onLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const onSetMaxValueClick = () => {
        setLimit(LOGGED_IN_USERS_MAX_LIMIT);
    };

    return (
        <Page loading={loading} error={error}>
            <Modal
                open={modalOpen}
                text="Are you sure you want to save?"
                onConfirm={saveLicenseCount}
                onClose={() => setModalOpen(false)}
            />
            <SuccessAlert message="License Count saved" show={snackbarVisible} />
            <Section>
                <SectionTitle title="Limits" />
                <SectionContent>
                    <FieldsContainer>
                        <Typography>
                            Maximum value of&nbsp;
                            {LOGGED_IN_USERS_MAX_LIMIT}
                        </Typography>
                        <TextField
                            label="License Count"
                            type="number"
                            color="secondary"
                            onChange={onLimitChange}
                            id="logged-in-users-limit"
                            autoFocus
                            value={limit}
                            error={isOutsideOfLimit()}
                            helperText={isOutsideOfLimit() && "License count outside of limit"}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={onSetMaxValueClick}
                            id="btn-set-max"
                        >
                            <Typography variant="caption">Set Max License Count</Typography>
                        </Button>
                        <TextField
                            label="Reason"
                            color="secondary"
                            onChange={onReasonChange}
                            id="logged-in-users-reason"
                            inputProps={{
                                maxLength: REASON_MAX_LENGTH,
                            }}
                            value={reason}
                            helperText={`Maximum length of ${REASON_MAX_LENGTH}`}
                        />
                    </FieldsContainer>
                </SectionContent>
                <SectionFooter>
                    <SectionFooterButton
                        onClick={() => setModalOpen(true)}
                        disabled={isSaveDisabled()}
                        buttonText="SAVE"
                    />
                </SectionFooter>
            </Section>
        </Page>
    );
};

export default License;
