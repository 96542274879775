import { Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DialogContent from "@material-ui/core/DialogContent";

const MessageContainer = styled.div`
    max-width: 340px;
`;

const AliasDomainDialogMessage = ({ enabled, error }) => (
    <MessageContainer>
        <DialogContent>
            {enabled ? (
                <Typography align="justify">
                    SPF record validated, you can now add the alias domain.
                </Typography>)
                : <Typography align="justify" color="error">{error}</Typography>}
        </DialogContent>
    </MessageContainer>
);

AliasDomainDialogMessage.propTypes = {
    enabled: PropTypes.bool.isRequired,
    error: PropTypes.string,
};

export default AliasDomainDialogMessage;
