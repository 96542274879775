import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import emptyListImage from "assets/no_content.svg";

const EmptyListElement = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
`;

const EmptyListText = styled.span`
    padding: 20px;
`;

const EmptyList = ({ text }) => (
    <EmptyListElement>
        <img src={emptyListImage} alt="No items" />
        <EmptyListText>{text}</EmptyListText>
    </EmptyListElement>
);

EmptyList.propTypes = {
    text: PropTypes.string,
};

export default EmptyList;
