/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as api from "utils/api";
import { Page, Section, SectionTabs } from "components/Page";
import DetailsSection from "../DetailsSection";
import OptionsSection from "../OptionsSection";
import AliasDomainsSection from "../DomainAliasesSection/AliasDomainsSection";

const EditCompany = () => {
    const [company, setCompany] = useState({ name: "..." });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTabIndex, setActiveTab] = React.useState(0);

    const { companyId } = useParams();

    const loadCompany = React.useCallback(async () => {
        try {
            const response = await api.get(`company/${companyId}`);
            setCompany(response.data);
            setLoading(false);
            setError(null);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    }, [companyId]);

    React.useEffect(() => {
        setLoading(true);
        loadCompany();
    }, [companyId, loadCompany]);

    const updateHeader = (companyName) => setCompany((prevState) => ({ ...prevState, name: companyName }));

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Page loading={loading} error={error}>
            <SectionTabs tabs={["Details", "Configuration", "Domains"]} activeTabIndex={activeTabIndex} onTabChange={handleChange} />
            {activeTabIndex === 0
                && <Section>
                    <DetailsSection key={company.id} company={company} onHeaderUpdate={updateHeader} />
                </Section>}
            {activeTabIndex === 1
                && <Section>
                    <OptionsSection key={company.id} companyId={company.id} options={company.companyOptions} />
                </Section>}
            {activeTabIndex === 2
                && <Section>
                    <AliasDomainsSection
                        key={company.id}
                        aliasDomains={company.aliasDomains}
                        loadCompany={loadCompany}
                        companyId={companyId}
                    />
                </Section>}
        </Page>
    );
};

export default EditCompany;
