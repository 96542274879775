export const COMPANY_DETAILS = "Company Details";
export const COMPANY_DETAILS_TEXT = "Update the name, website and options of the company";

export const COMPANY_SUBSCRIPTION = "Subscription";
export const COMPANY_SUBSCRIPTION_TEXT = "View details of the company's subscription";

export const COMPANY_LICENSE = "License";
export const COMPANY_LICENSE_TEXT = "Manage the company's licenses";

export const CONFIGURATION_IMPORT = "Configuration Import";
export const CONFIGURATION_IMPORT_TEXT = "Import Dynamic data types, Queues and Users";

export const COMPANY_MENU_SEGMENT = "CompanyMenuSegment";

export const ACCOUNTSS_HEADING = "Accounts";
export const ACCOUNTSS_SUBHEADING = "Overview of accounts and the ability to update"
    + " certain types of accounts on this domain";

export const USERS = "Users";
export const USERS_SUBHEADING = "View all users and invite new ones";

export default {
    COMPANY_DETAILS,
    COMPANY_DETAILS_TEXT,
    COMPANY_SUBSCRIPTION,
    COMPANY_SUBSCRIPTION_TEXT,
    COMPANY_LICENSE,
    COMPANY_LICENSE_TEXT,
    CONFIGURATION_IMPORT,
    CONFIGURATION_IMPORT_TEXT,
    COMPANY_MENU_SEGMENT,
    ACCOUNTSS_HEADING,
    ACCOUNTSS_SUBHEADING,
    USERS,
    USERS_SUBHEADING,
};
