import { DialogContent, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DialogActions from "@material-ui/core/DialogActions";
import * as api from "utils/api";
import AliasDomainDialogMessage from "../AliasDomainDialogMessage/AliasDomainDialogMessage";

const AliasDomainContainer = styled(DialogContent)`
    display: flex;
    flex-direction: row;
`;
AliasDomainContainer.displayName = "AliasDomainContainer";

const AliasDomainTextField = styled(TextField)`
    width: 100%;
`;
AliasDomainTextField.displayName = "AliasDomainTextField";

const AliasDomainDialogContent = ({
    companyId, onClose, domains,
}) => {
    const [domainName, setDomainName] = React.useState("");
    const [enabled, setEnabled] = React.useState(false);
    const [error, setError] = React.useState("");

    const domainRegex = new RegExp(/(^(?=[a-z0-9-]{1,63})[a-z0-9]+([.-]+([a-z0-9]){1,63}){0,4}$)/);
    const verifySpfRecord = async () => {
        setEnabled(false);
        if (domains.includes(domainName)) {
            setError("The alias domain already exists");
        } else if (domainRegex.test(domainName)) {
            setError("");
            try {
                await api.post(`alias-domain/${companyId}/validate`, { AliasDomain: domainName })
                    .then(async (result) => {
                        setEnabled(result.data.verified);
                        setError(result.data.error);
                    });
            } catch (err) {
                setError(err.message);
            }
        } else {
            setError("Please enter a valid domain name");
        }
    };

    const addAliasDomain = async () => {
        try {
            await api.put(`company/${companyId}/alias-domain`, { AliasDomain: domainName });
        } catch (err) {
            onClose(err);
        }
        onClose();
    };

    return (
        <>
            <DialogContent>
                <AliasDomainTextField
                    label="Alias Domain"
                    onChange={(event) => setDomainName(event.target.value)}
                    value={domainName}
                    autoFocus
                    margin="dense"
                    disabled={enabled}
                />
            </DialogContent>
            <AliasDomainDialogMessage
                enabled={enabled}
                error={error}
            />
            <DialogActions>
                {enabled ? (
                    <Button
                        onClick={() => addAliasDomain()}
                        color="secondary"
                    >
                        Add Alias Domain
                    </Button>)
                    : (
                        <Button
                            disabled={domainName === ""}
                            onClick={() => verifySpfRecord()}
                            color="secondary"
                        >
                            Verify SPF Record
                        </Button>)}
            </DialogActions>
        </>
    );
};

AliasDomainDialogContent.propTypes = {
    companyId: PropTypes.string,
    onClose: PropTypes.func,
    domains: PropTypes.arrayOf(PropTypes.string),
};

export default AliasDomainDialogContent;
