import React from "react";
import styled from "styled-components";
import CompanyList from "./CompanyList";
import * as Constants from "./MainMenuConstants";
import UserPanel from "./UserPanel";

const Segment = styled.div`
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 100%;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.greyscale.white};
    border-right: solid 1px ${({ theme }) => theme.greyscale.grey4};
`;
Segment.displayName = Constants.MAIN_MENU_SEGMENT;

const MainMenu = () => (
    <Segment>
        <CompanyList />
        <UserPanel />
    </Segment>
);

export default MainMenu;
