import * as Constants from "../ImportConstants";

export const ImportDetailsTypeMap = {
    [Constants.DYNAMIC_DATA]: {
        title: Constants.DYNAMIC_DATA_IMPORT,
        subTitle: Constants.DYNAMIC_DATA_SUBTITLE,
        acceptedFileType: Constants.DYNAMIC_DATA_ACCEPTED_FILE_TYPE,
        label: Constants.DYNAMIC_DATA_LABEL,
        blobType: Constants.DYNAMIC_DATA_BLOB_TYPE,
        dataProperty: Constants.DYNAMIC_DATA_TYPES,
        importUrl: Constants.DYNAMIC_DATA_IMPORT_URL,
    },
    [Constants.QUEUES]: {
        title: Constants.QUEUES_IMPORT,
        subTitle: Constants.QUEUES_SUBTITLE,
        acceptedFileType: Constants.QUEUES_ACCEPTED_FILE_TYPE,
        label: Constants.QUEUES_LABEL,
        blobType: Constants.QUEUES_BLOB_TYPE,
        dataProperty: Constants.QUEUES_TYPES,
        importUrl: Constants.QUEUES_IMPORT_URL,
    },
    [Constants.SERVICE_LEVEL]: {
        title: Constants.SERVICE_LEVEL_IMPORT,
        subTitle: Constants.SERVICE_LEVEL_SUBTITLE,
        acceptedFileType: Constants.SERVICE_LEVEL_ACCEPTED_FILE_TYPE,
        label: Constants.SERVICE_LEVEL_LABEL,
        blobType: Constants.SERVICE_LEVEL_BLOB_TYPE,
        dataProperty: Constants.SERVICE_LEVEL_TYPES,
        importUrl: Constants.SERVICE_LEVEL_IMPORT_URL,
    },
    [Constants.USERS]: {
        title: Constants.USERS_IMPORT,
        subTitle: Constants.USERS_SUBTITLE,
        acceptedFileType: Constants.USERS_ACCEPTED_FILE_TYPE,
        label: Constants.USERS_LABEL,
        blobType: Constants.USERS_BLOB_TYPE,
        dataProperty: Constants.USERS_IMPORT_TYPES,
        importUrl: Constants.USERS_IMPORT_URL,
        confirmDataProperty: Constants.USERS_CONFIRM_TYPES,
    },
    unknown: {
        title: "Unknown",
        subTitle: "Unknown",
        acceptedFileType: "",
        label: "",
        blobType: "",
        dataProperty: null,
        importUrl: "",
        confirmDataProperty: null,
    },
};

export default ImportDetailsTypeMap;
