import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import ActionDialogTitle from "./ActionDialogTitle/ActionDialogTitle";

const StyledActionDialog = styled(Dialog)`
    & .MuiDialog-paper {
        min-width: 500px;
    }
`;

const ActionDialog = ({
    children, open, onClose, title,
}) => (
    <StyledActionDialog
        open={open}
        onClose={onClose}
    >
        <ActionDialogTitle title={title} />
        {children}
    </StyledActionDialog>
);

ActionDialog.propTypes = {
    children: PropTypes.element,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
};

export default ActionDialog;
