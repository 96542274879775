import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as api from "utils/api";
import { Page, Section, SectionTabs } from "components/Page";
import RegistrationSection from "./RegistrationSection/RegistrationSection";
import BillingSection from "./BillingSection/BillingSection";
import CompanySuspension from "./CompanySuspension";

const Subscription = () => {
    const [company, setCompany] = useState({ name: "..." });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTabIndex, setActiveTab] = React.useState(0);

    const { companyId } = useParams();

    const loadCompany = React.useCallback(async () => {
        try {
            const response = await api.get(`company/${companyId}`);
            setCompany(response.data);
            setLoading(false);
            setError(null);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    }, [companyId]);

    React.useEffect(() => {
        setLoading(true);
        loadCompany();
    }, [companyId, loadCompany]);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Page loading={loading} error={error}>
            <SectionTabs tabs={["Registration", "Subscription"]} activeTabIndex={activeTabIndex} onTabChange={handleChange} />
            {activeTabIndex === 0
                && (
                    <Section>
                        <CompanySuspension suspension={company.suspension} />
                        <RegistrationSection
                            key={company.id}
                            registration={company.registration}
                        />
                    </Section>
                )}
            {activeTabIndex === 1
                && (
                    <Section>
                        <BillingSection
                            key={company.id}
                            billing={company.billingSettings}
                        />
                    </Section>
                )}
        </Page>
    );
};

export default Subscription;
