/* eslint-disable operator-linebreak */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import * as api from "utils/api";
import { formatDate } from "utils/dateHelpers";
import { Page } from "components/Page";
import {
    Section,
    SectionTitle,
    SectionContent,
    SectionFooter,
    SectionFooterButton,
} from "components/Page/Section";
import SuccessAlert from "components/Alerts";
import HealthDisplay from "components/HealthDisplay";
import { COMPANY, USERS } from "domain/Router/RouterConstants";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 5px;
`;

const Label = styled(Typography)`
    font-weight: 600;
    min-width: 200px;
`;

const UserDetails = () => {
    const [healthText, setHealthText] = useState(null);
    const [userDetails, setUserDetails] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updateSuccessful, setUpdateSuccessful] = useState(false);

    const { companyId, userId } = useParams();

    const setHealth = (disabled, deleted) => {
        if (disabled) {
            setHealthText("User disabled");
        }
        if (deleted) {
            setHealthText("User deleted");
        }
    };

    const enableUser = async () => {
        try {
            await api.put(`${COMPANY}/${companyId}/${USERS}/${userId}/enable`);
            setUserDetails({ ...userDetails, disabled: false });
            setHealth(userDetails.disabled, userDetails.deleted);
            setUpdateSuccessful(true);
        } catch (err) {
            setError(err.message);
        }
    };

    const disableUser = async () => {
        try {
            await api.put(`${COMPANY}/${companyId}/${USERS}/${userId}/disable`);
            setUserDetails({ ...userDetails, disabled: true });
            setHealth(userDetails.disabled, userDetails.deleted);
            setUpdateSuccessful(true);
        } catch (err) {
            setError(err.message);
        }
    };

    const undeleteUser = async () => {
        try {
            await api.put(`${COMPANY}/${companyId}/${USERS}/${userId}/undelete`);
            setUserDetails({ ...userDetails, disabled: true, deleted: null });
            setHealth(userDetails.disabled, userDetails.deleted);
            setUpdateSuccessful(true);
        } catch (err) {
            setError(err.message);
        }
    };

    const giveAdminLicense = async () => {
        try {
            await api.put(`${COMPANY}/${companyId}/${USERS}/${userId}/admin-license/enable`);
            setUserDetails({ ...userDetails, adminLicense: true });
            setUpdateSuccessful(true);
        } catch (err) {
            setError(err.message);
        }
    };

    const removeAdminLicense = async () => {
        try {
            await api.put(`${COMPANY}/${companyId}/${USERS}/${userId}/admin-license/disable`);
            setUserDetails({ ...userDetails, adminLicense: false });
            setUpdateSuccessful(true);
        } catch (err) {
            setError(err.message);
        }
    };

    React.useEffect(() => {
        const loadUserDetails = async () => {
            try {
                const response = await api.get(`${COMPANY}/${companyId}/${USERS}/${userId}`);
                const payload = response.data;
                setUserDetails(payload);
                setHealth(payload.disabled, payload.deleted);
                setError(null);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        setLoading(true);
        loadUserDetails();
    }, [userId, companyId]);

    return (
        <Page loading={loading} error={error}>
            <Section>
                <SectionTitle title={`${userDetails.forename} ${userDetails.surname}`} hideBack={false} />
                <SectionContent>
                    <HealthDisplay
                        healthy={!userDetails.disabled && !userDetails.deleted}
                        unhealthyText={healthText}
                    />
                    <Row key="created">
                        <Label variant="subtitle2">Created:</Label>
                        <Typography
                            variant="body1"
                            data-testid="account-detail-channel"
                        >
                            {formatDate(userDetails.created)}
                        </Typography>
                    </Row>
                    <Row key="type">
                        <Label variant="subtitle2">Type:</Label>
                        <Typography
                            variant="body1"
                            data-testid="account-detail-type"
                        >
                            {userDetails.isExternal ? "External" : "Internal"}
                        </Typography>
                    </Row>
                    {userDetails.isExternal &&
                        <Row key="external-source">
                            <Label variant="subtitle2">External Source:</Label>
                            <Typography
                                variant="body1"
                                data-testid="account-detail-external-source"
                            >
                                {userDetails.externalSource}
                            </Typography>
                        </Row>}
                    <Row key="lastActive">
                        <Label variant="subtitle2">Last Active:</Label>
                        <Typography
                            variant="body1"
                            data-testid="account-detail-channel"
                        >
                            {userDetails.lastActive ? formatDate(userDetails.lastActive) : "Unknown"}
                        </Typography>
                    </Row>
                    {userDetails.deleted &&
                        <Row key="deleted">
                            <Label variant="subtitle2">Deleted:</Label>
                            <Typography
                                variant="body1"
                                data-testid="account-detail-channel"
                            >
                                {formatDate(userDetails.deleted)}
                            </Typography>
                        </Row>}
                    <Row key="email">
                        <Label variant="subtitle2">Email:</Label>
                        <Typography
                            variant="body1"
                            data-testid="account-detail-channel"
                        >
                            {userDetails.email}
                        </Typography>
                    </Row>
                    <Row key="state">
                        <Label variant="subtitle2">State:</Label>
                        <Typography
                            variant="body1"
                            data-testid="account-detail-channel"
                        >
                            {userDetails.state}
                        </Typography>
                    </Row>
                    <Row key="adminLicense">
                        <Label variant="subtitle2">Admin License:</Label>
                        <Typography
                            variant="body1"
                            data-testid="account-detail-admin-license"
                        >
                            {userDetails.adminLicense === true ? "true" : "false"}
                        </Typography>
                    </Row>
                </SectionContent>
                <SectionFooter>
                    {userDetails.disabled && !userDetails.deleted
                        && <SectionFooterButton
                            disabled={false}
                            onClick={() => enableUser()}
                            buttonText="Enable"
                        />}
                    {!userDetails.disabled && !userDetails.deleted
                        && <SectionFooterButton
                            disabled={false}
                            onClick={() => disableUser()}
                            buttonText="Disable"
                        />}
                    {userDetails.deleted
                        && <SectionFooterButton
                            disabled={false}
                            onClick={() => undeleteUser()}
                            buttonText="Undelete"
                        />}
                    {userDetails.adminLicense
                        && <SectionFooterButton
                            disabled={false}
                            onClick={() => removeAdminLicense()}
                            buttonText="Remove Admin License"
                        />}
                    {(!userDetails.adminLicense || userDetails.adminLicense === false)
                        && <SectionFooterButton
                            disabled={false}
                            onClick={() => giveAdminLicense()}
                            buttonText="Give Admin License"
                        />}
                </SectionFooter>
                <SuccessAlert message="User details updated" show={updateSuccessful} />
            </Section>
        </Page>
    );
};

export default UserDetails;
