import React from "react";
import PropTypes from "prop-types";
import Label from "components/MaterialUi/Label";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import RenderTableFactory from "./SummaryTables/RenderTableFactory";

const Container = styled.div`
    position: relative;
    width: 100%;
    min-height: 100px;
`;

export const MessageTypography = styled(Typography)`
    color: ${(props) => (props.error ? "red" : null)};
    margin-top: 30px;
    max-width: 400px;
`;

const labelText = "File Confirmation";

const ImportSummary = ({
    collectionValues, message, error, importType, importSummaryType,
}) => {
    if (error) {
        return (
            <Container>
                <MessageTypography variant="subtitle1" error={error}>
                    {`There was a problem processing the selected file: ${message}`}
                </MessageTypography>
            </Container>
        );
    }

    let table = null;
    if (collectionValues !== null && collectionValues.length > 0) {
        table = <>
            <Label>{labelText}</Label>
            <RenderTableFactory
                importType={importType}
                collectionValues={collectionValues}
                importSummaryType={importSummaryType}
            />
            {/* eslint-disable-next-line react/jsx-closing-tag-location */}
        </>;
    }

    let messageTypography = null;
    if (message) {
        messageTypography = <MessageTypography variant="subtitle1">{message}</MessageTypography>;
    }

    return (
        <Container>
            {messageTypography}
            {table}
        </Container>
    );
};

ImportSummary.defaultProps = {
    collectionValues: [],
    error: null,
    message: "",
};

ImportSummary.propTypes = {
    collectionValues: PropTypes.oneOfType(
        [
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.arrayOf(PropTypes.number),
        ]
    ),
    error: PropTypes.bool,
    message: PropTypes.string,
    importType: PropTypes.string.isRequired,
    importSummaryType: PropTypes.string,
};

export default ImportSummary;
