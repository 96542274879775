import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { put } from "utils/api";
import { objectsAreDifferent } from "utils/objectHelper";
import { matchesTerm } from "utils/textHelpers";
import Error from "components/Error";
import {
    SectionTitle,
    SectionContent,
    SectionFooter,
    SectionFooterButton,
} from "components/Page/Section";
import EmptyList from "components/EmptyList";
import SuccessAlert from "components/Alerts";
import Modal from "components/Modal";
import SearchBoxContainer from "components/SearchBox/SearchBoxContainer";
import ListItemView from "components/ListItemView";
import NewCompanyOption from "./NewCompanyOption";
import ImportOptions from "./ImportOptions";
import {
    optionsSectionReducerInitializer, optionsSectionReducer,
} from "./optionsSectionReducer";
import * as optionsSectionActions from "./optionsSectionActions";

const SUCCESS_TIMEOUT = 5000;

const CLEAR = "clear all";
const SAVE = "save";

const OptionsSection = ({ companyId, options: initialOptions }) => {
    const [state, dispatch] = useReducer(optionsSectionReducer, initialOptions, optionsSectionReducerInitializer);

    const onDeleteOption = (key) => dispatch(optionsSectionActions.removeOption(key));
    const onUpdateOption = (key, value) => dispatch(optionsSectionActions.addOrUpdateOption(key, value));
    const clearOptions = () => dispatch(optionsSectionActions.setOptions({}));
    const onOptionsImport = (options) => dispatch(optionsSectionActions.setOptions(options));
    const searchOptions = ({ target: { value } }) => dispatch(optionsSectionActions.setSearchTerm(value));
    const setModalUse = (use) => dispatch(optionsSectionActions.setModalUse(use));

    const saveOptions = async () => {
        dispatch(optionsSectionActions.setSaving(true));

        try {
            await put(`company/${companyId}/options`, { options: state.options });
            dispatch(optionsSectionActions.setSavedOptions(state.options));
            dispatch(optionsSectionActions.setSaving(false));
            dispatch(optionsSectionActions.setShowSuccessAlert(true));
        } catch (error) {
            dispatch(optionsSectionActions.setErrorMessage(error.userMessage));
            dispatch(optionsSectionActions.setSaving(false));
        }
    };

    useEffect(() => {
        setTimeout(() => {
            dispatch(optionsSectionActions.setShowSuccessAlert(false));
        }, SUCCESS_TIMEOUT);
    }, [state.showSuccessAlert]);

    const unfilteredOptions = Object.entries(state.options);
    const unfilteredKeys = Object.keys(state.options);

    const checkOptionsForDuplicate = (key) => unfilteredKeys.includes(key);

    const noOptionsExist = unfilteredOptions.length === 0;

    const emptyOptionKeysExist = unfilteredKeys.some((key) => !key.length);

    const unsavedOptionChanges = objectsAreDifferent(state.savedOptions, state.options);

    const filteredOptions = state.searchTerm
        ? unfilteredOptions
            .filter(([key, value]) => matchesTerm(key, state.searchTerm) || matchesTerm(value, state.searchTerm))
        : unfilteredOptions;

    const filteredOptionsExist = filteredOptions.length > 0;

    const onConfirmClick = async () => {
        const action = state.modalUse === CLEAR
            ? clearOptions
            : saveOptions;

        setModalUse(null);
        await action();
    };

    if (state.errorMessage) {
        return <Error error={state.errorMessage} />;
    }

    return (
        <>
            <Modal
                open={!!state.modalUse}
                text={`Are you sure you want to ${state.modalUse}?`}
                onConfirm={onConfirmClick}
                onClose={() => setModalUse(null)}
            />
            <SectionTitle title="Configuration" />
            <SectionContent>
                <SearchBoxContainer
                    color="secondary"
                    fullWidth
                    placeholder="Search"
                    type="search"
                    onChange={searchOptions}
                />
                <NewCompanyOption
                    onAddClick={onUpdateOption}
                    onDuplicateCheck={checkOptionsForDuplicate}
                />
                {
                    noOptionsExist
                    && <ImportOptions
                        companyId={companyId}
                        onOptionsImport={onOptionsImport}
                    />
                }
                {
                    filteredOptions
                        .map(([key, value], index) => ((
                            <ListItemView
                                key={`${index}-${key}`}
                                optionKey={key}
                                optionValue={value}
                                showCopyButton
                                showDeleteButton
                                onUpdate={onUpdateOption}
                                onDelete={onDeleteOption}
                            />
                        )))
                }
                {
                    (!filteredOptionsExist && !noOptionsExist) && <EmptyList text="There are no options to display" />
                }
                <SuccessAlert message="Configuration Saved" show={state.showSuccessAlert} />
            </SectionContent>
            <SectionFooter>
                <SectionFooterButton
                    onClick={() => setModalUse(SAVE)}
                    disabled={!unsavedOptionChanges || state.saving || emptyOptionKeysExist}
                    buttonText="SAVE"
                />
                <SectionFooterButton
                    onClick={() => setModalUse(CLEAR)}
                    disabled={noOptionsExist}
                    buttonText="CLEAR"
                    variant="text"
                    color="secondary"
                />
            </SectionFooter>
        </>
    );
};

OptionsSection.propTypes = {
    options: PropTypes.shape({}),
    companyId: PropTypes.string,
};

OptionsSection.defaultProps = {
    companyId: "",
    options: {},
};

export default OptionsSection;
