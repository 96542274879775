import React from "react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as ScThemeProvider } from "styled-components";
import Router from "domain/Router";
import { gnattaTheme, gnattaMuiTheme } from "./gnattaTheme";

export const App = () => (
    <MuiThemeProvider theme={gnattaMuiTheme}>
        <ScThemeProvider theme={gnattaTheme}>
            <CssBaseline />
            <Router />
        </ScThemeProvider>
    </MuiThemeProvider>
);

export default App;
