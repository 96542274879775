import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
    Avatar, ListItem, ListItemAvatar, ListItemText,
} from "@material-ui/core";
import ImportIcon from "@material-ui/icons/SdCard";
import ListContainer from "components/Page/Containers/ListContainer";
import { CONFIGURATION_IMPORT } from "domain/Router/RouterConstants";
import { DYNAMIC_DATA, DYNAMIC_DATA_IMPORT } from "../ImportConstants";

const Segment = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100vh;
    max-height: 100vh;
    padding: 0px;
    overflow: auto;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme.greyscale.grey6};
`;

export const ImportList = () => {
    const history = useHistory();

    const selectImport = (type) => history.push(`/${CONFIGURATION_IMPORT}/${type}`);

    const isSelected = (type) => history.location.pathname.endsWith(type);

    return (
        <Segment>
            <ListContainer>
                <ListItem
                    key={DYNAMIC_DATA}
                    button
                    onClick={() => selectImport(DYNAMIC_DATA)}
                    selected={isSelected(DYNAMIC_DATA)}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <ImportIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={DYNAMIC_DATA_IMPORT} />
                </ListItem>
            </ListContainer>
        </Segment>);
};

export default ImportList;
