import React from "react";
import PropTypes from "prop-types";
import {
    Button, DialogActions, DialogTitle, DialogContent,
} from "@material-ui/core";

const DefaultDialog = ({ submitMediaAccountUpdates, resetValuesWhenNotSaved }) => (
    <>
        <DialogTitle>Update Media Account</DialogTitle>
        <DialogContent>
            <span>Are you sure you want to update?</span>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={submitMediaAccountUpdates}
                color="primary"
            >
                Submit
            </Button>
            <Button
                onClick={resetValuesWhenNotSaved}
                color="secondary"
            >
                Cancel
            </Button>
        </DialogActions>
    </>
);

DefaultDialog.propTypes = {
    submitMediaAccountUpdates: PropTypes.func.isRequired,
    resetValuesWhenNotSaved: PropTypes.func,
};

export default DefaultDialog;
