import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { SectionContent } from "components/Page/Section";
import {
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
} from "@material-ui/core";
import gnattaTheme from "gnattaTheme";
import SearchBoxContainer from "components/SearchBox/SearchBoxContainer";
import EmptyList from "components/EmptyList";
import CancelIcon from "@material-ui/icons/Cancel";
import { sortByField } from "utils/arrayHelpers";
import { matchesTerm } from "utils/textHelpers";
import Error from "components/Error";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { COMPANY, USERS } from "domain/Router/RouterConstants";
import * as userListActions from "./userListActions";
import { userListReducer, userListReducerInitializer } from "./userListReducer";

const UserItem = styled(ListItem)`
    &:hover {
        background-color: ${({ theme }) => theme.background};
        cursor: pointer;

        .MuiListItemText-secondary {
            color: ${({ theme }) => theme.brand}
        }
    }
`;

const UserAvatar = styled(ListItemAvatar)`
    text-transform: uppercase;

    &.active .MuiAvatar-colorDefault {
        background-color: ${({ theme }) => theme.brand};
    }
`;

const UserDeletedIcon = styled(CancelIcon)`
    color: ${({ theme }) => theme.error};
    margin-top: -14px;
    margin-left: 26px;
    position: absolute;
`;

const UsersList = ({ loading, users, error }) => {
    const { companyId } = useParams();
    const { push } = useHistory();
    const handleNavigate = (path) => push(path);

    const [state, dispatch] = useReducer(userListReducer,
        users,
        userListReducerInitializer);

    const searchOptions = ({ target: { value } }) => dispatch(userListActions.setSearchTerm(value));

    const allUsers = Object.entries(sortByField(users, "forename"));

    const filteredUsers = state.searchTerm
        ? allUsers
            .filter(([, value]) => matchesTerm(value.forename, state.searchTerm) || matchesTerm(value.surname, state.searchTerm))
        : allUsers;

    if (loading) {
        return <LoadingSpinner height="150px" />;
    }

    if (error) {
        return <Error error={error} />;
    }

    return (
        <SectionContent>
            <SearchBoxContainer
                color="secondary"
                fullWidth
                type="search"
                onChange={searchOptions}
            />
            {filteredUsers.length
                ? (
                    <>
                        {filteredUsers
                            .map(([key, user]) => (
                                <UserItem
                                    key={key}
                                    divider
                                    onClick={() => handleNavigate(`/${COMPANY}/${companyId}/${USERS}/${user.id}`)}
                                    style={user.disabled ? { backgroundColor: (gnattaTheme.greyscale.grey5) } : {}}
                                >
                                    <UserAvatar className={!user.disabled && "active"}>
                                        <>
                                            <Avatar>
                                                {`${user.forename.substring(0, 1)}${user.surname.substring(0, 1)}`}
                                            </Avatar>
                                            {user.deleted
                                                && <UserDeletedIcon />}
                                        </>
                                    </UserAvatar>
                                    <ListItemText
                                        primary={`${user.forename} ${user.surname}${user.disabled ? " (disabled)" : ""}`}
                                        secondary={user.id}
                                    />
                                </UserItem>
                            ))}
                    </>
                )
                : <EmptyList text="There are no users to display" />}
        </SectionContent>
    );
};

UsersList.propTypes = {
    loading: PropTypes.bool,
    users: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        created: PropTypes.string,
        disabled: PropTypes.bool,
        email: PropTypes.string,
        forename: PropTypes.string,
        surname: PropTypes.string,
        configuration: PropTypes.shape({
            companyId: PropTypes.string,
            roles: PropTypes.arrayOf(PropTypes.string),
        }),
    })).isRequired,
    error: PropTypes.string,
};

UsersList.defaultProps = {
    loading: true,
    error: "",
};

export default UsersList;
