/* eslint-disable react/jsx-closing-tag-location */
import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "components/Page/Section/SectionTitle";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import HealthyIcon from "assets/success_icon.svg";
import UnhealthyIcon from "assets/failed_icon.svg";
import * as dateHelpers from "utils/dateHelpers";

const Subtitle = styled(Typography)`
    font-weight: 600;
    min-width: 200px;
`;

const Image = styled.img`
    pointer-events: none;
    width: 30px;
    height: 30px;
    padding: 0px;
`;

const ImageContainer = styled.div`
    display: flex;
`;

const Divider = styled.hr`
    height: 2px;
    border-width: 0;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 5px;
`;

export const intervalDisplayText = {
    1: "Monthly",
    3: "Quarterly",
    12: "Annually",
    null: "N/A",
};

const BillingSection = ({ billing }) => (
    <>
        <SectionTitle title="Subscription Details">
            {billing
            && <Tooltip
                id="health-tooltip"
                title={billing.paymentError ? billing.paymentError : "Healthy"}
                placement="top"
                arrow
            >
                <ImageContainer>
                    <Image
                        data-testid="billing-payment"
                        src={billing.paymentError ? UnhealthyIcon : HealthyIcon}
                        alt={billing.paymentError ? billing.paymentError : "Healthy"}
                    />
                </ImageContainer>
            </Tooltip>}
        </SectionTitle>
        {billing != null
            ? <ContentContainer>
                <Row>
                    <Subtitle variant="subtitle2">Tier:&nbsp;</Subtitle>
                    <Typography variant="body1" data-testid="billing-tier">
                        {billing.tier ? billing.tier[0].toUpperCase() + billing.tier.slice(1) : "N/A"}
                    </Typography>
                </Row>
                <Divider />
                <Row>
                    <Subtitle variant="subtitle2">Interval:&nbsp;</Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="billing-interval"
                    >
                        {intervalDisplayText[billing.interval]}
                    </Typography>
                </Row>
                <Divider />
                <Row>
                    <Subtitle variant="subtitle2">Licenses:&nbsp;</Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="billing-quantity"
                    >
                        {billing.quantity ? billing.quantity : "N/A"}
                    </Typography>
                </Row>
                <Divider />
                <Row>
                    <Subtitle variant="subtitle2">End Date:&nbsp;</Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="billing-end-date"
                    >
                        {billing.endDate ? dateHelpers.getLocalTime(billing.endDate, "DD/MM/YYYY HH:mm") : "N/A"}
                    </Typography>
                </Row>
                <Divider />
                <Row>
                    <Subtitle variant="subtitle2">Next Bill (Due):&nbsp;</Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="billing-bill-date"
                    >
                        {billing.billDate ? dateHelpers.getLocalTime(billing.billDate, "DD/MM/YYYY HH:mm") : "N/A"}
                    </Typography>
                </Row>
                <Divider />
                <Row>
                    <Subtitle variant="subtitle2">Card Details Added:&nbsp;</Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="billing-card"
                    >
                        {billing.cardDetailsAdded ? "Yes" : "No"}
                    </Typography>
                </Row>
                <Divider />
                <Row>
                    <Subtitle variant="subtitle2">Payment Details Added:&nbsp;</Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="billing-address"
                    >
                        {billing.addressDetailsAdded ? "Yes" : "No"}
                    </Typography>
                </Row>
            </ContentContainer>
            : <ContentContainer>
                There are no subscription details available
            </ContentContainer>}
    </>
);

BillingSection.propTypes = {
    billing: PropTypes.shape({
        endDate: PropTypes.string,
        billDate: PropTypes.string,
        tier: PropTypes.string,
        interval: PropTypes.number,
        quantity: PropTypes.number,
        cardDetailsAdded: PropTypes.bool,
        addressDetailsAdded: PropTypes.bool,
        paymentError: PropTypes.string,
    }),
};

export default BillingSection;
