export const sortAlphabetically = (array) => array.sort((a, b) => {
    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
});

const toLower = (value) => {
    if (!value) return "";
    return value.toLowerCase();
};

export const sortByField = (array, field) => array.sort((a, b) => {
    if (toLower(a[field]) > toLower(b[field])) return 1;
    if (toLower(b[field]) > toLower(a[field])) return -1;
    return 0;
});

export default { sortAlphabetically, sortByField };
