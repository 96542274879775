import moment from "moment";

export const formatDate = (date, format = "DD/MM/YYYY HH:mm") => moment(date).format(format);

const formatCreated = (created, createdBy = null) => {
    const date = formatDate(created, "DD/MM/YYYY HH:mm");
    return `Created ${createdBy ? `by ${createdBy} on ` : ""}${date}`;
};

const formatCreatedShort = (created, createdBy = null) => {
    const date = formatDate(created, "DD/MM/YYYY HH:mm");
    return `${date}${createdBy ? ` (${createdBy})` : ""}`;
};

export const getLocalTime = (date, format) => (
    moment.utc(date).local().format(format)
);

export const isBefore = (firstDateTime, secondDateTime) => moment.utc(firstDateTime).isBefore(moment.utc(secondDateTime));

export default {
    formatDate, formatCreated, formatCreatedShort, getLocalTime, isBefore,
};
