import * as constants from "./optionsSectionConstants";

export const optionsSectionReducerInitializer = (initialOptions) => ({
    options: initialOptions,
    savedOptions: initialOptions,
    searchTerm: null,
    saving: false,
    showSuccessAlert: false,
    errorMessage: null,
    modalUse: null,
});

export const optionsSectionReducer = (state, action) => {
    if (!action) return state;

    switch (action.type) {
        case constants.SET_SAVED_OPTIONS: {
            const savedOptions = { ...action.payload.savedOptions };
            return { ...state, savedOptions };
        }
        case constants.SET_OPTIONS: {
            const options = { ...action.payload.options };
            return { ...state, options };
        }
        case constants.ADD_OR_UPDATE_OPTION: {
            return {
                ...state,
                options: {
                    ...state.options,
                    [action.payload.key.trim()]: action.payload.value.trim(),
                },
            };
        }
        case constants.REMOVE_OPTION: {
            const options = { ...state.options };
            delete options[action.payload.key];
            return { ...state, options };
        }
        case constants.SET_SEARCH_TERM: {
            return { ...state, searchTerm: action.payload.searchTerm };
        }
        case constants.SET_SAVING: {
            return { ...state, saving: action.payload.saving };
        }
        case constants.SET_SHOW_SUCCESS_ALERT: {
            return { ...state, showSuccessAlert: action.payload.showSuccessAlert };
        }
        case constants.SET_ERROR_MESSAGE: {
            return { ...state, errorMessage: action.payload.errorMessage };
        }
        case constants.SET_MODAL_USE: {
            return { ...state, modalUse: action.payload.modalUse };
        }
        default:
            return state;
    }
};
