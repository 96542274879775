import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as api from "utils/api";
import { Page, Section, SectionTabs } from "components/Page";
import SectionTitle from "components/Page/Section/SectionTitle";
import InvitesList from "./InvitesList/InvitesList";
import UsersList from "./UsersList/UsersList";
import InviteUserDialog from "./InviteUserDialog/InviteUserDialog";

const Users = () => {
    const [invitesLoading, setInvitesLoading] = useState(true);
    const [invitesError, setInvitesError] = useState(null);
    const [invites, setInvites] = useState([]);
    const [usersLoading, setUsersLoading] = useState(true);
    const [usersError, setUsersError] = useState(null);
    const [users, setUsers] = useState([]);
    const [deletedLoading, setDeletedLoading] = useState(true);
    const [deletedError, setDeletedError] = useState(null);
    const [deleted, setDeleted] = useState([]);
    const [modalOpen, toggleModal] = useState(false);
    const [activeTabIndex, setActiveTab] = React.useState(0);

    const { companyId } = useParams();

    React.useEffect(() => {
        const loadCompanyInvites = async () => {
            try {
                const response = await api.get(`company/${companyId}/users/invites`);
                setInvites(response.data);
                setInvitesLoading(false);
                setInvitesError(null);
            } catch (err) {
                setInvitesLoading(false);
                setInvitesError(err.message);
            }
        };

        const loadCompanyUsers = async () => {
            try {
                const response = await api.get(`company/${companyId}/users`);
                setUsers(response.data);
                setUsersLoading(false);
                setUsersError(null);
            } catch (err) {
                setUsersLoading(false);
                setUsersError(err.message);
            }
        };

        const loadDeletedUsers = async () => {
            try {
                const response = await api.get(`company/${companyId}/users/deleted`);
                setDeleted(response.data);
                setDeletedLoading(false);
                setDeletedError(null);
            } catch (err) {
                setDeletedLoading(false);
                setDeletedError(err.message);
            }
        };

        loadCompanyInvites();
        loadCompanyUsers();
        loadDeletedUsers();
    }, [companyId]);

    const onModalClose = (newInvite) => {
        if (newInvite) {
            invites.push(newInvite);
        }
        toggleModal(false);
    };

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Page>
            <SectionTabs tabs={["Users", "Invites", "Deleted"]} activeTabIndex={activeTabIndex} onTabChange={handleChange} />
            {activeTabIndex === 0
                && (
                    <Section>
                        <SectionTitle title="All Users" />
                        <UsersList loading={usersLoading} users={users} error={usersError} />
                    </Section>
                )}
            {activeTabIndex === 1
                && (
                    <Section>
                        <SectionTitle title="User Invites" onAdd={() => toggleModal(true)} />
                        <InviteUserDialog
                            open={modalOpen}
                            onClose={onModalClose}
                            companyId={companyId}
                        />
                        <InvitesList loading={invitesLoading} invites={invites} error={invitesError} />
                    </Section>
                )}
            {activeTabIndex === 2
                && (
                    <Section>
                        <SectionTitle title="Deleted Users" />
                        <UsersList loading={deletedLoading} users={deleted} error={deletedError} />
                    </Section>
                )}
        </Page>
    );
};

export default Users;
