import React from "react";
import { Page } from "components/Page";
import styled from "styled-components";
import headerImage from "assets/AccessDeniedHeader.png";

const ErrorContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const Img = styled.img`
    height: 30vh;
`;

const TextWrapper = styled.div`
    padding: 0 20px;
    text-align: center;
    color: ${({ theme }) => theme.greyscale.grey2};
`;

const errrorText = "These aren't the droids you're looking for...";

const AccessDenied = () => (
    <Page>
        <ErrorContainer>
            <div>
                <Img src={headerImage} alt={errrorText} />
                <TextWrapper>
                    <h2>{errrorText}</h2>
                    {/* eslint-disable-next-line max-len */}
                    <p>You do not currently have permission to access this site, please contact support if you think you should have access.</p>
                </TextWrapper>
            </div>
        </ErrorContainer>
    </Page>
);

export default AccessDenied;
