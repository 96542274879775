import React from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import List from "components/MaterialUi/List";
import {
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
} from "@material-ui/core";
import { useCompanyContext } from "context/CompanyContext";
import {
    COMPANY, COMPANY_SUBSCRIPTION, COMPANY_DETAILS, COMPANY_LICENSE, CONFIGURATION_IMPORT, ACCOUNTS, USERS,
} from "domain/Router/RouterConstants";
import * as Constants from "./CompanyMenuConstants";

const Menu = styled.div`
    position: relative;
    width: 300px;
    background-color: ${({ theme }) => theme.greyscale.white};
    border: solid 1px ${({ theme }) => theme.greyscale.grey4};
    margin-right: 10px;
`;
Menu.displayName = Constants.COMPANY_MENU_SEGMENT;

const CompanyListItem = styled(ListItem)`

    &:hover {
        background-color: ${({ theme }) => theme.background};

        .MuiListItemText-secondary {
            color: ${({ theme }) => theme.brand}
        }

        .MuiAvatar-colorDefault {
            color: ${({ theme }) => theme.greyscale.white};
            background-color: ${({ theme }) => theme.brand};
        }
    }

    &.Mui-selected {
        background-color: ${({ theme }) => theme.background};

        .MuiListItemText-secondary {
            color: ${({ theme }) => theme.brand}
        }

        .MuiAvatar-colorDefault {
            color: ${({ theme }) => theme.greyscale.white};
            background-color: ${({ theme }) => theme.brand};
        }
    }

    .MuiListItemText-primary {
        font-weight: bold;
    }

    .MuiAvatar-colorDefault {
        color: ${({ theme }) => theme.greyscale.black};
        background-color: ${({ theme }) => theme.greyscale.grey5};
    }
`;
CompanyListItem.displayName = "CompanyListItem";

const CompanyMenu = () => {
    const { companyId } = useParams();
    const { push, location: { pathname } } = useHistory();
    const handleNavigate = (path) => push(path);
    const path = pathname.split("/")[3];

    const { companies } = useCompanyContext();
    const company = companies.find((x) => x.id === companyId);

    if (!company) {
        return (<Menu />);
    }

    return (
        <Menu>
            <List component="nav">
                <CompanyListItem
                    button
                    selected={path === COMPANY_DETAILS}
                    onClick={() => handleNavigate(`/${COMPANY}/${companyId}/${COMPANY_DETAILS}`)}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <AccountBalanceIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={Constants.COMPANY_DETAILS}
                        secondary={Constants.COMPANY_DETAILS_TEXT}
                    />
                </CompanyListItem>
                <CompanyListItem
                    button
                    selected={path === COMPANY_SUBSCRIPTION}
                    onClick={() => handleNavigate(`/${COMPANY}/${companyId}/${COMPANY_SUBSCRIPTION}`)}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <ReceiptIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={Constants.COMPANY_SUBSCRIPTION}
                        secondary={Constants.COMPANY_SUBSCRIPTION_TEXT}
                    />
                </CompanyListItem>
                <CompanyListItem
                    button
                    selected={path === COMPANY_LICENSE}
                    onClick={() => handleNavigate(`/${COMPANY}/${companyId}/${COMPANY_LICENSE}`)}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <VerifiedUserIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={Constants.COMPANY_LICENSE}
                        secondary={Constants.COMPANY_LICENSE_TEXT}
                    />
                </CompanyListItem>
                <CompanyListItem
                    button
                    selected={path === CONFIGURATION_IMPORT}
                    onClick={() => handleNavigate(`/${COMPANY}/${companyId}/${CONFIGURATION_IMPORT}`)}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <ImportExportIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={Constants.CONFIGURATION_IMPORT}
                        secondary={Constants.CONFIGURATION_IMPORT_TEXT}
                    />
                </CompanyListItem>
                <CompanyListItem
                    button
                    selected={path === ACCOUNTS}
                    onClick={() => handleNavigate(`/${COMPANY}/${companyId}/${ACCOUNTS}`)}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <SettingsEthernetIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={Constants.ACCOUNTSS_HEADING}
                        secondary={Constants.ACCOUNTSS_SUBHEADING}
                    />
                </CompanyListItem>
                <CompanyListItem
                    button
                    selected={path === USERS}
                    onClick={() => handleNavigate(`/${COMPANY}/${companyId}/${USERS}`)}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <SupervisorAccountIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={Constants.USERS}
                        secondary={Constants.USERS_SUBHEADING}
                    />
                </CompanyListItem>
            </List>
        </Menu>
    );
};

export default CompanyMenu;
