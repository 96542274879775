import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SectionElement = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 20px;
    box-sizing: border-box;
`;

const Section = ({ children }) => (
    <SectionElement>
        {children}
    </SectionElement>
);

Section.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Section;
