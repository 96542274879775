import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import logo from "assets/logo/gnatta-logo-horizontal-colour.svg";

const Header = styled.div`
    display: flex;
    height: 80px;
    align-items: center;
    box-sizing: border-box;
    border-bottom: solid 1px ${({ theme }) => theme.greyscale.grey4};
    padding: 0 20px;
`;

const SiteName = styled.div`
    display: flex;
    cursor: pointer;

    img {    
        width: 175px;
    }

    span {
        font-size: 2rem;
        padding-top: 5px;
    }
`;

const SiteHeader = () => {
    const { push } = useHistory();
    return (
        <Header>
            <SiteName onClick={() => push("/")}>
                <img src={logo} alt="Gnatta Admin" />
                <span>Admin</span>
            </SiteName>
        </Header>
    );
};

export default SiteHeader;
