import React, { useRef } from "react";
import { Button as MuiButton } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import Label from "components/MaterialUi/Label";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MuiClearIcon from "@material-ui/icons/HighlightOff";

export const Section = styled.div`
    display: flex;
    width: 25rem;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    align-items: center;
`;

export const ErrorSection = styled(Section)`
    border-bottom: 2px solid #EB5757;
`;

const StyledAttachIcon = styled(AttachFileIcon)`
    fill: rgba(0, 0, 0, 0.42);
    height: 30px;
    &:hover {
        fill: black;   
    }
`;

const ClearIcon = styled(MuiClearIcon)`
    fill: ${(props) => props.fill};
    &:hover {
        fill: ${(props) => props.hover};
    }
`;

export const Button = styled(MuiButton)`
    min-width: 30px;
    padding: 3px 0px;
    justify-content: flex-start;
    transition: none;
    &:hover {
        background-color: transparent;
    }
`;

export const ClearButton = styled(Button)`
    margin-left: auto;
`;

export const ErrorLabel = styled(Label)`
    color: #EB5757;
    margin-top: 5px;
`;

export const FileUploadContainer = ({
    onFileSelect,
    selectedFilename,
    valid,
    label,
    disabled,
    acceptedFileType,
}) => {
    const fileInput = useRef(null);

    const handleClick = () => {
        fileInput.current.click();
    };

    const handleChange = ({ target: { files } }) => {
        const file = files[0];
        if (!file) return;
        onFileSelect(file);
    };

    const handleClear = () => {
        fileInput.current.value = null;
        onFileSelect(null);
    };

    const Container = selectedFilename && !valid ? ErrorSection : Section;
    const clearIconFill = selectedFilename && valid ? "rgba(0, 0, 0, 0.42)" : "#EB5757";
    const clearIconHoverFill = selectedFilename && valid ? "black" : "rgba(0, 0, 0, 0.42)";

    return (
        <>
            <Label>{label}</Label>
            <Container>
                <Button onClick={handleClick} disableTouchRipple>
                    <input
                        ref={fileInput}
                        accept={acceptedFileType}
                        type="file"
                        onChange={handleChange}
                        disabled={disabled}
                        style={{ display: "none" }}
                    />
                    <StyledAttachIcon />
                </Button>
                {selectedFilename}
                {/* eslint-disable-next-line operator-linebreak */}
                {selectedFilename && !disabled &&
                    <ClearButton
                        id="clear-button"
                        onClick={handleClear}
                    >
                        <ClearIcon fill={clearIconFill} hover={clearIconHoverFill} />
                    </ClearButton>}
            </Container>
            {selectedFilename
                // eslint-disable-next-line operator-linebreak
                && !valid &&
                <ErrorLabel>
                    {`File is not of the required type - must be '${acceptedFileType}'`}
                </ErrorLabel>}
        </>
    );
};

FileUploadContainer.propTypes = {
    onFileSelect: PropTypes.func.isRequired,
    selectedFilename: PropTypes.string,
    valid: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    acceptedFileType: PropTypes.string.isRequired,
};

FileUploadContainer.defaultProps = {
    selectedFilename: "",
    label: "",
    disabled: false,
};

export default FileUploadContainer;
