import * as actionTypes from "./importDetailsActionTypes";
import { UPLOAD } from "../ImportConstants";

export const importDetailsReducerInitialState = {
    importId: "",
    importData: {
        data: null,
        summaryType: null,
    },
    stepType: UPLOAD,
    importStatus: {
        isUploading: false,
        message: "",
        hasError: false,
    },
};

export const importDetailsReducer = (state, action) => {
    if (!action) return state;

    switch (action.type) {
        case actionTypes.SET_IMPORT_ID: {
            return { ...state, importId: action.payload.importId };
        }
        case actionTypes.SET_IMPORT_DATA: {
            return { ...state, importData: action.payload.importData };
        }
        case actionTypes.SET_STEP_TYPE: {
            return { ...state, stepType: action.payload.stepType };
        }
        case actionTypes.SET_IMPORT_STATUS: {
            return { ...state, importStatus: action.payload.importStatus };
        }
        default:
            return state;
    }
};
