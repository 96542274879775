import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
    DialogContent,
    DialogActions,
    TextField,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel,
    InputLabel,
    Checkbox,
    Button,
} from "@material-ui/core";
import * as api from "utils/api";
import validationHelpers from "utils/validationHelpers";
import ActionDialog from "components/ActionDialog";

const EmailAddressField = styled(TextField)`
    width: 300px;
`;

const LanguageSelect = styled(FormControl)`
    width: 150px;
    margin-left: 20px;
    margin-top: 5px;
`;

const ErrorContainer = styled.div`
    color: ${({ theme }) => theme.error};
`;

const InviteUserDialog = ({ open, onClose, companyId }) => {
    const [enabled, setEnabled] = useState(true);
    const [formValid, setFormValid] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [emailAddressError, setEmailAddressError] = useState("");
    const [language, setLanguage] = useState("en-GB");
    const [isOwner, setIsOwner] = useState(false);
    const [error, setError] = useState(null);

    const resetForm = () => {
        setEmailAddress("");
        setEmailAddressError("");
        setLanguage("en-GB");
        setIsOwner(false);
        setFormValid(false);
        setEnabled(true);
        setError(null);
    };

    const validateEmailAddress = (value) => {
        setEmailAddress(value);

        if (!validationHelpers.isEmail(value)) {
            setEmailAddressError("Must be a valid email address");
            setFormValid(false);
            return;
        }

        setEmailAddressError(null);
        setFormValid(true);
    };

    const sendInvite = async () => {
        setEnabled(false);

        try {
            const request = {
                emailAddress,
                language,
                isOwner,
            };
            const response = await api.post(`company/${companyId}/users/invites`, request);
            resetForm();
            onClose(response.data);
        } catch (err) {
            if (err.errors) {
                setError(err.errors.EmailAddress.join(", "));
            } else {
                setError(err.technicalMessage);
            }
        }
        setEnabled(true);
    };

    return (
        <ActionDialog
            title="Invite User"
            onClose={() => {
                resetForm();
                onClose();
            }}
            open={open}
        >
            <>
                <DialogContent>
                    <div>
                        <EmailAddressField
                            label="Email Address"
                            onChange={(event) => { validateEmailAddress(event.target.value); }}
                            value={emailAddress}
                            helpertext={emailAddressError}
                            error={emailAddressError}
                            autoFocus
                            margin="dense"
                            disabled={!enabled}
                            required
                        />
                        <LanguageSelect variant="standard">
                            <InputLabel id="invite-language-label">Language</InputLabel>
                            <Select
                                labelId="invite-language-label"
                                onChange={(event) => setLanguage(event.target.value)}
                                value={language}
                                disabled={!enabled}
                            >
                                <MenuItem value="en-GB">English</MenuItem>
                                <MenuItem value="fr-FR">Français</MenuItem>
                                <MenuItem value="de-DE">Deutsch</MenuItem>
                                <MenuItem value="it-IT">Italiano</MenuItem>
                                <MenuItem value="ru-RU">Русский</MenuItem>
                                <MenuItem value="es-ES">Español</MenuItem>
                                <MenuItem value="ko-KR">한국어</MenuItem>
                                <MenuItem value="nl-NL">Nederlands</MenuItem>
                                <MenuItem value="sv-SE">Svenska</MenuItem>
                            </Select>
                        </LanguageSelect>
                    </div>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    label="Give user Owner role?"
                                    value={isOwner}
                                    onChange={(event) => setIsOwner(event.target.checked)}
                                />
                            }
                            label="Give user Owner role?"
                            disabled={!enabled}
                        />
                    </div>
                    {error && <ErrorContainer>{error}</ErrorContainer>}
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!formValid}
                        onClick={() => sendInvite()}
                        color="secondary"
                    >
                        Send Invite
                    </Button>
                </DialogActions>
            </>
        </ActionDialog>
    );
};

InviteUserDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    companyId: PropTypes.string,
};

export default InviteUserDialog;
