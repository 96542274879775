import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import * as api from "utils/api";
import SmallDeleteButton from "components/Buttons/SmallDeleteButton";
import DeleteAliasDomainDialog from "./DeleteAliasDomainDialog";

const AliasDomainContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px;
`;

const Left = styled.div`
    min-width: 30%;
`;

const AliasDomain = ({ companyId, domain, loadCompany }) => {
    const [error, setError] = React.useState("");
    const [modalOpen, setModalOpen] = React.useState(false);

    const onDelete = () => {
        setModalOpen(true);
    };

    const onClose = () => {
        setModalOpen(false);
    };

    const confirmOnDelete = async () => {
        setError("");
        try {
            await api.post(`company/${companyId}/delete-alias-domain`, { aliasDomain: domain });
        } catch (err) {
            setError(err.userMessage || err.message || err);
        }
        setModalOpen(false);
        loadCompany();
    };

    return (
        <AliasDomainContainer>
            <Left>
                <Typography color="textSecondary">{domain}</Typography>
            </Left>
            <SmallDeleteButton onDelete={onDelete} />
            <DeleteAliasDomainDialog
                open={modalOpen}
                onClose={onClose}
                onDelete={confirmOnDelete}
                domain={domain}
            />
            {error !== ""
            && <Typography color="error">{error}</Typography>}
        </AliasDomainContainer>
    );
};

AliasDomain.propTypes = {
    companyId: PropTypes.string,
    domain: PropTypes.string,
    loadCompany: PropTypes.func,
};

export default AliasDomain;
