import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import headerImage from "assets/ErrorLogo.png";

const ErrorContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 10px;
`;

const TextWrapper = styled.div`
    padding: 10px;
    text-align: center;
    color: ${({ theme }) => theme.greyscale.grey2};
`;

const Error = ({ error }) => (
    <ErrorContainer>
        <div>
            <img src={headerImage} alt="An error occured" />
            <TextWrapper>
                <h2>Uhoh!</h2>
                <p>{error}</p>
            </TextWrapper>
        </div>
    </ErrorContainer>
);

Error.propTypes = {
    error: PropTypes.string,
};

export default Error;
