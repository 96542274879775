import * as constants from "./mediaAccountListConstants";

export const mediaAccountListReducerInitializer = (initialMediaAccounts) => ({
    mediaAccounts: initialMediaAccounts,
    searchTerm: null,
});

export const mediaAccountListReducer = (state, action) => {
    if (!action) return state;

    switch (action.type) {
        case constants.SEARCH_ACCOUNTS: {
            return { ...state, searchTerm: action.payload.searchTerm };
        }
        default:
            return state;
    }
};
