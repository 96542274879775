import * as actionType from "./importDetailsActionTypes";

export const setImportId = (importId) => ({
    type: actionType.SET_IMPORT_ID,
    payload: { importId },
});

export const setImportData = (importData) => ({
    type: actionType.SET_IMPORT_DATA,
    payload: { importData },
});

export const resetImportData = () => ({
    type: actionType.SET_IMPORT_DATA,
    payload: { importData: { data: null, summaryType: null } },
});

export const setImportStatus = (importStatus) => ({
    type: actionType.SET_IMPORT_STATUS,
    payload: { importStatus },
});

export const setStepType = (stepType) => ({
    type: actionType.SET_STEP_TYPE,
    payload: { stepType },
});
