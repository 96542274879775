import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import HealthyIcon from "@material-ui/icons/CheckCircle";
import UnhealthyIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const Container = styled.div`
    display: flex;
    align-items: center;
    border: ${({ healthy, theme }) => `2px solid ${healthy ? theme.success : theme.error}`};
    padding: 15px;
    margin-left: auto;
    margin-bottom: 10px;
`;

const StyledHealthyIcon = styled(HealthyIcon)`
    height: 50px;
    width: 50px;
    fill: ${({ theme }) => theme.success};
`;

const StyledUnhealthyIcon = styled(UnhealthyIcon)`
    height: 50px;
    width: 50px;
    fill: ${({ theme }) => theme.error};
`;

const TextContainer = styled.div`
    margin-left: 6px;
`;

const Header = styled.p`
    margin: 0;
    font-weight: 700;
    line-height: 1.25rem;
    font-size: 0.875rem;
`;

const HealthText = styled.p`
    margin: 0;
`;

const LightTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: "#ffffff",
        color: "#000000",
        fontSize: "0.875rem",
    },
    arrow: {
        color: "#ffffff",
    },
}))(Tooltip);

const HealthComponent = ({ healthy, healthyText, unhealthyText }) => (
    <>
        {healthy ? <StyledHealthyIcon /> : <StyledUnhealthyIcon />}
        <TextContainer>
            <Header>Health:</Header>
            <HealthText>
                {healthy ? healthyText : unhealthyText}
            </HealthText>
        </TextContainer>
    </>
);

HealthComponent.propTypes = {
    healthy: PropTypes.bool.isRequired,
    healthyText: PropTypes.string,
    unhealthyText: PropTypes.string,
};

const HealthDisplay = ({
    healthy,
    healthyText,
    unhealthyText,
    reason,
}) => (
    reason ? (
        <LightTooltip
            id="health-tooltip"
            title={reason}
            placement="top"
            arrow
        >
            <Container healthy={healthy}>
                <HealthComponent healthy={healthy} healthyText={healthyText} unhealthyText={unhealthyText} />
            </Container>
        </LightTooltip>
    ) : (
        <Container healthy={healthy}>
            <HealthComponent healthy={healthy} healthyText={healthyText} unhealthyText={unhealthyText} />
        </Container>
    )
);

HealthDisplay.propTypes = {
    healthy: PropTypes.bool,
    healthyText: PropTypes.string,
    unhealthyText: PropTypes.string,
    reason: PropTypes.string,
};

HealthDisplay.defaultProps = {
    healthy: false,
    healthyText: "Healthy",
    unhealthyText: "Unhealthy",
};

export default HealthDisplay;
