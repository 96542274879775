import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useCompanyContext } from "context/CompanyContext";
import { Typography } from "@material-ui/core";
import dateHelpers from "utils/dateHelpers";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    height: 100px;
    padding: 0 30px;
    justify-content: center;
    background-color: ${({ theme }) => theme.brand};
    color: #fff;
`;

const Page = styled.div`
    display: flex;
    flex-direction: row;                    
    flex: 1 1 auto;
    padding-top: 10px;
    height: calc(100% - 110px);
`;

const CompanyPage = ({ children }) => {
    const { companyId } = useParams();

    const { companies } = useCompanyContext();
    const company = companies.find((x) => x.id === companyId);

    if (!company) {
        return (<div />);
    }

    const createdText = dateHelpers.formatCreated(company.created, company.createdBy);

    return (
        <Container>
            <Header>
                <Typography variant="h4">{company.name}</Typography>
                <Typography variant="subtitle2">{createdText}</Typography>
            </Header>
            <Page>
                {children}
            </Page>
        </Container>
    );
};

CompanyPage.propTypes = {
    children: PropTypes.node,
};

export default CompanyPage;
