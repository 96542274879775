import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Fab, Typography } from "@material-ui/core";

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px ${({ theme }) => theme.greyscale.grey4};
    align-items: center;

    h3 {
        flex: 1 1 auto;
        font-size: 1.2rem;
        font-weight: bold;
    }

    button {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        align-items: center;
        border: 0;
        background-color: transparent;
        color: ${({ theme }) => theme.brand};
        font-weight: bold;
        cursor: pointer;
    }
`;

const BackButton = styled(Fab)`
    width: 36px;
    height: 36px;
    margin-right: 10px;
    box-shadow: none;

    span {
        justify-content: end;
    }
`;

const AddButton = styled.button`
    padding: 0;
`;
AddButton.displayName = "AddButton";

const SectionTitle = ({
    title,
    children,
    onAdd,
    hideBack,
}) => {

    const history = useHistory();

    return (
        <TitleContainer>
            {!hideBack
                && (
                    <BackButton onClick={() => history.goBack()}>
                        <ArrowBackIosIcon />
                    </BackButton>
                )}
            <Typography variant="h3">{title}</Typography>
            {children}
            {onAdd
                && (
                    <AddButton onClick={onAdd}>
                        <AddIcon />
                        Add
                    </AddButton>
                )}
        </TitleContainer>
    );

};

SectionTitle.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    onAdd: PropTypes.func,
    hideBack: PropTypes.bool,
};

SectionTitle.defaultProps = {
    hideBack: true,
};

export default SectionTitle;
