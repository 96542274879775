export const DENIED = "denied";
export const COMPANY_DETAILS = "details";
export const COMPANY_SUBSCRIPTION = "subscription";
export const COMPANY_LICENSE = "license";
export const COMPANY = "company";
export const CONFIGURATION_IMPORT = "configuration-import";
export const ACCOUNTS = "accounts";
export const USERS = "users";

export default {
    DENIED,
    COMPANY_DETAILS,
    COMPANY_SUBSCRIPTION,
    COMPANY_LICENSE,
    COMPANY,
    CONFIGURATION_IMPORT,
    ACCOUNTS,
    USERS,
};
