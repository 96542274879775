/* eslint-disable react/jsx-closing-tag-location */
import React from "react";
import PropTypes from "prop-types";
import { SectionTitle, SectionContent } from "components/Page/Section";
import { Box, Typography } from "@material-ui/core";
import AliasDomain from "./AliasDomain";
import NewAliasDomainDialog from "./NewAliasDomain/NewAliasDomainDialog";

const AliasDomainsSection = ({ aliasDomains, companyId, loadCompany }) => {
    const [modalOpen, toggleModalOpen] = React.useState(false);

    const onClose = () => {
        toggleModalOpen(false);
        loadCompany();
    };

    return (
        <>
            <SectionTitle title="Alias Domains" onAdd={() => toggleModalOpen(true)} />
            <NewAliasDomainDialog
                open={modalOpen}
                onClose={onClose}
                companyId={companyId}
                domains={aliasDomains || []}
            />
            <SectionContent>
                { (aliasDomains && aliasDomains.length > 0)
                    ? aliasDomains.map((domain) => (
                        <AliasDomain domain={domain} key={domain} companyId={companyId} loadCompany={loadCompany} />))
                    : <Typography variant="body2" color="textPrimary">
                        <Box fontWeight="fontWeightBold">No Alias Domains exist for this company</Box>
                    </Typography>}
            </SectionContent>
        </>
    );
};

AliasDomainsSection.propTypes = {
    aliasDomains: PropTypes.arrayOf(PropTypes.string),
    companyId: PropTypes.string,
    loadCompany: PropTypes.func,
};

export default AliasDomainsSection;
