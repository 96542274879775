import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { isNotNullOrWhitespace } from "utils/textHelpers";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: solid 2px ${({ theme }) => theme.greyscale.grey4};
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
`;

const Header = styled.div`
    font-weight: bold;
`;

const Content = styled.div`
    display: flex;
`;

const KeyField = styled(TextField)`
    flex: 1 1 auto;
    margin-right: 10px;
`;
KeyField.displayName = "KeyField";

const ValueField = styled(TextField)`
    flex: 1 1 auto;
    margin-right: 10px;
`;
ValueField.displayName = "ValueField";

const AddButton = styled.button`
    display: flex;
    flex: 0 0 auto;
    color: ${({ theme }) => theme.brand};
    background-color: ${({ theme }) => theme.greyscale.white};
    border: 0;
    font-weight: bold;
    justify-content: center;
    align-items: center;
`;
AddButton.displayName = "AddButton";

const initialEntryState = { key: "", value: "" };

const NewCompanyOption = ({ onDuplicateCheck, onAddClick }) => {
    const [entry, setEntry] = useState(initialEntryState);

    const handleChange = (value, field) => {
        setEntry((prevState) => ({ ...prevState, [field]: value }));
    };

    const handleAddClick = () => {
        onAddClick(entry.key, entry.value);
        setEntry(initialEntryState);
    };

    const isDuplicate = onDuplicateCheck(entry.key);
    const isNullOrWhitespace = entry.value && !isNotNullOrWhitespace(entry.value);

    const showAddButton = (!!entry.key && !!entry.value && !isDuplicate);

    return (
        <>
            <Container>
                <Header>Add a Company Option</Header>
                <Content>
                    <KeyField
                        label="New Key"
                        value={entry.key}
                        color="secondary"
                        onChange={({ target }) => handleChange(target.value, "key")}
                        error={isDuplicate}
                        helperText={isDuplicate ? "Duplicate Key" : null}
                    />
                    <ValueField
                        label="New Value"
                        value={entry.value}
                        color="secondary"
                        onChange={({ target }) => handleChange(target.value, "value")}
                        error={!!isNullOrWhitespace}
                        helperText={isNullOrWhitespace ? "Invalid Company Option" : null}
                    />
                    <AddButton
                        color="secondary"
                        aria-label="add"
                        size="small"
                        onClick={handleAddClick}
                        disabled={!showAddButton}
                    >
                        <AddIcon />
                        Add
                    </AddButton>
                </Content>
            </Container>
        </>
    );
};

NewCompanyOption.propTypes = {
    onDuplicateCheck: PropTypes.func.isRequired,
    onAddClick: PropTypes.func.isRequired,
};

export default NewCompanyOption;
