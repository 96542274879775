import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { SectionTitle } from "components/Page/Section";
import styled from "styled-components";
import * as dateHelpers from "utils/dateHelpers";

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
`;

const Subtitle = styled(Typography)`
    font-weight: 600;
    min-width: 200px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 5px;
`;

const CompanySuspension = ({ suspension }) => {
    if (!suspension) {
        return null;
    }

    const isSuspended = dateHelpers.isBefore(suspension.suspendAt, new Date().toISOString());
    const suspendTitle = isSuspended ? "Suspended:" : "Suspend At:";

    return (
        <>
            <SectionTitle title="Company Suspended" />
            <ContentContainer>
                <Row>
                    <Subtitle variant="subtitle2">
                        {suspendTitle}
                        &nbsp;
                    </Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="suspend-at"
                    >
                        {dateHelpers.getLocalTime(suspension.suspendAt, "DD/MM/YYYY HH:mm")}
                    </Typography>
                </Row>
            </ContentContainer>
        </>
    );
};

CompanySuspension.propTypes = {
    suspension: PropTypes.shape({
        suspendAt: PropTypes.string,
    }),
};

export default CompanySuspension;
