import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BlockIcon from "@material-ui/icons/Block";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { isBefore, formatDate } from "utils/dateHelpers";

const SuspendingMessage = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;    
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.warn};
    color: ${({ theme }) => theme.error};
    font-size: 1rem;

    & svg {
        margin-right: 10px;
    }
`;

const SuspendedMessage = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;    
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.error};
    color: ${({ theme }) => theme.error};
    font-size: 1rem;

    & svg {
        margin-right: 10px;
    }
`;

const SuspensionMessage = ({ date }) => {
    const isSuspended = isBefore(date, new Date().toISOString());
    const messageText = isSuspended
        ? `This company was suspended on ${formatDate(date)}`
        : `This company will be suspended on ${formatDate(date)}`;

    return (
        <>
            {isSuspended
                ? (
                    <SuspendedMessage>
                        <BlockIcon />
                        {messageText}
                    </SuspendedMessage>
                )
                : (
                    <SuspendingMessage>
                        <ScheduleIcon />
                        {messageText}
                    </SuspendingMessage>
                )}
        </>
    );
};

SuspensionMessage.propTypes = {
    date: PropTypes.string,
};

export default SuspensionMessage;
