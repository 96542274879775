import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Content = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    background-color: ${({ theme }) => theme.greyscale.grey5};
    height: calc(100% - 80px);
`;

const SiteContent = ({ children }) => (
    <Content>
        {children}
    </Content>);

SiteContent.propTypes = {
    children: PropTypes.node,
};

export default SiteContent;
