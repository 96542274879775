import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography, Avatar, CardActionArea } from "@material-ui/core";

const Card = styled.div`
    height: 200px;
    width: 250px;
    margin: 0 10px 10px 0;
    background-color: ${({ theme }) => theme.greyscale.grey4};
    border: solid 1px ${({ theme }) => theme.greyscale.grey3};
    float: left;

    &:hover {
        background-color: ${({ theme }) => theme.background};

        .MuiAvatar-colorDefault {
            color: ${({ theme }) => theme.greyscale.white};
            background-color: ${({ theme }) => theme.brand};
        }
    }
`;

const ActionArea = styled(CardActionArea)`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
`;

const CardIconPanel = styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
`;

const CardIconAvatar = styled(Avatar)`
    width: 50px;
    height: 50px;
    color: ${({ theme }) => theme.greyscale.black};
    background-color: ${({ theme }) => theme.greyscale.white};
`;

const CardText = styled.div`
    flex: 1 1 auto;
    text-align: center;

    h3 {
        font-size: 1.2rem;
        font-weight: bold;
    }
`;
CardText.displayName = "CardText";

ActionArea.displayName = "CardActionArea";

const ConfigImportButton = ({ option, onClick }) => {
    const Icon = option.icon;
    return (
        <Card>
            <ActionArea
                onClick={onClick}
                disabled={option.disabled}
            >
                <CardIconPanel>
                    <CardIconAvatar>
                        <Icon />
                    </CardIconAvatar>
                </CardIconPanel>
                <CardText>
                    <Typography variant="h3">{option.text}</Typography>
                    <Typography>{option.helperText}</Typography>
                </CardText>
            </ActionArea>
        </Card>
    );
};

ConfigImportButton.propTypes = {
    option: PropTypes.shape({
        text: PropTypes.string.isRequired,
        helperText: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        icon: PropTypes.shape(),
    }),
    onClick: PropTypes.func,
};

ConfigImportButton.defaultProps = {
    onClick: () => {},
};

export default ConfigImportButton;
