import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import TextField from "components/MaterialUi/TextField";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 5px;
`;

const Label = styled(Typography)`
    font-weight: 600;
    min-width: 200px;
`;
Label.displayName = "Label";

TextField.displayName = "MediaAccountEmailAddress";

const InternalMediaAccountDetailsForm = ({
    value,
    onChange,
    error,
    helperText,
}) => (
    <Row key="name">
        <Label variant="subtitle2">Email Address:</Label>
        <TextField
            style={{ minWidth: "70%" }}
            error={!!error}
            defaultValue={value}
            color="secondary"
            onChange={onChange}
            helperText={helperText}
        />
    </Row>
);

InternalMediaAccountDetailsForm.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
};

export default InternalMediaAccountDetailsForm;
