import React from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { ListItem, ListItemText, ListItemAvatar } from "@material-ui/core";
import ChannelIcon from "components/ChannelIcon";
import { formatDate } from "utils/dateHelpers";
import theme from "gnattaTheme";
import { COMPANY, ACCOUNTS } from "domain/Router/RouterConstants";

const MediaAccountRow = ({
    name,
    channel,
    added,
    disabled,
    id,
}) => {
    const { companyId } = useParams();
    const { push } = useHistory();
    const handleNavigate = (path) => push(path);

    return (
        <ListItem
            button
            divider
            onClick={() => handleNavigate(`/${COMPANY}/${companyId}/${ACCOUNTS}/${id}`)}
            key={id}
            style={disabled ? { backgroundColor: (theme.greyscale.grey5) } : {}}
        >
            <ListItemAvatar>
                <ChannelIcon channel={channel} />
            </ListItemAvatar>
            <ListItemText
                primary={`${name}${disabled ? " (disabled)" : ""}`}
                secondary={`Added ${formatDate(added)}`}
            />
        </ListItem>
    );
};

MediaAccountRow.propTypes = {
    name: PropTypes.string,
    channel: PropTypes.string,
    added: PropTypes.string,
    disabled: PropTypes.string,
    id: PropTypes.string,
};

export default MediaAccountRow;
