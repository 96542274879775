/* eslint-disable max-len */
// Dyanmic Data
export const DYNAMIC_DATA = "dynamic-data";
export const DYNAMIC_DATA_SUBTITLE = "Below you can import Dynamic Data Types, their options and conditions to a domain";
export const DYNAMIC_DATA_IMPORT = "Dynamic Data Import";
export const DYNAMIC_DATA_ACCEPTED_FILE_TYPE = ".csv";
export const DYNAMIC_DATA_BLOB_TYPE = "text/csv";
export const DYNAMIC_DATA_LABEL = "File (CSV only)";
export const DYNAMIC_DATA_IMPORT_URL = "dynamic-data/types/import";
export const DYNAMIC_DATA_TYPES = "dynamicDataTypes";

// Queues
export const QUEUES = "queues";
export const QUEUES_SUBTITLE = "Below you can import Queues & their associated information";
export const QUEUES_IMPORT = "Queues Import";
export const QUEUES_ACCEPTED_FILE_TYPE = ".csv";
export const QUEUES_BLOB_TYPE = "text/csv";
export const QUEUES_LABEL = "File (CSV only)";
export const QUEUES_IMPORT_URL = "queues/import";
export const QUEUES_TYPES = "queues";

// Service Level Agreements
export const SERVICE_LEVEL = "service-level";
export const SERVICE_LEVEL_SUBTITLE = "Below you can import Service level agreements, position & colour";
export const SERVICE_LEVEL_IMPORT = "Service Level Agreement Import";
export const SERVICE_LEVEL_ACCEPTED_FILE_TYPE = ".csv";
export const SERVICE_LEVEL_BLOB_TYPE = "text/csv";
export const SERVICE_LEVEL_LABEL = "File (CSV only)";
export const SERVICE_LEVEL_IMPORT_URL = "service-levels/import";
export const SERVICE_LEVEL_TYPES = "data";

// Users
export const USERS = "users";
export const USERS_SUBTITLE = "Below you can import new users with their permissions and channel capacities";
export const USERS_IMPORT = "Users Import";
export const USERS_ACCEPTED_FILE_TYPE = ".csv";
export const USERS_BLOB_TYPE = "text/csv";
export const USERS_LABEL = "File (CSV only)";
export const USERS_IMPORT_URL = "users/import";
export const USERS_IMPORT_TYPES = "numberOfUsers";
export const USERS_CONFIRM_TYPES = "data";

export const AWAITING_CONFIRM = "The file is now being processed. This may take some time. Please do not refresh the page.";
export const CONFIRM = "confirm";
export const UPLOAD_ERROR = "An error occurred whilst processing the file. Please try again";
export const UPLOAD_COMPLETE = "File has been successfully imported to the domain.";
export const UPLOAD = "upload";

export const HEXADECIMAL_REGEX = /^#[0-9A-F]{6}$/i;

export default {
    DYNAMIC_DATA,
    DYNAMIC_DATA_SUBTITLE,
    DYNAMIC_DATA_IMPORT,
    DYNAMIC_DATA_ACCEPTED_FILE_TYPE,
    DYNAMIC_DATA_LABEL,
    DYNAMIC_DATA_IMPORT_URL,
    QUEUES,
    QUEUES_SUBTITLE,
    QUEUES_IMPORT,
    QUEUES_ACCEPTED_FILE_TYPE,
    QUEUES_BLOB_TYPE,
    QUEUES_LABEL,
    QUEUES_IMPORT_URL,
    QUEUES_TYPES,
    SERVICE_LEVEL,
    SERVICE_LEVEL_SUBTITLE,
    SERVICE_LEVEL_IMPORT,
    SERVICE_LEVEL_ACCEPTED_FILE_TYPE,
    SERVICE_LEVEL_BLOB_TYPE,
    SERVICE_LEVEL_LABEL,
    SERVICE_LEVEL_IMPORT_URL,
    SERVICE_LEVEL_TYPES,
    USERS,
    USERS_SUBTITLE,
    USERS_IMPORT,
    USERS_ACCEPTED_FILE_TYPE,
    USERS_BLOB_TYPE,
    USERS_LABEL,
    USERS_IMPORT_URL,
    USERS_IMPORT_TYPES,
    USERS_CONFIRM_TYPES,
    AWAITING_CONFIRM,
    CONFIRM,
    UPLOAD,
    UPLOAD_COMPLETE,
    UPLOAD_ERROR,
    HEXADECIMAL_REGEX,
};
