import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Error from "components/Error";
import LoadingSpinner from "components/LoadingSpinner";

const Segment = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: calc(100% - 70px);
    padding: 0px;
    overflow: auto;
    overflow-x: hidden;
`;

const MenuSecondary = ({ loading, error, children }) => {
    if (error) {
        return (
            <Segment>
                <Error error={error} />
            </Segment>
        );
    }

    if (loading) {
        return (
            <Segment>
                <LoadingSpinner height="100px" />
            </Segment>
        );
    }

    return (
        <Segment>
            {children}
        </Segment>
    );
};

MenuSecondary.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    children: PropTypes.node.isRequired,
};

MenuSecondary.defaultProps = {
    loading: true,
};

export default MenuSecondary;
