import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle as MuiDialogTitle,
} from "@material-ui/core";
import styled from "styled-components";

const DialogTitle = styled(MuiDialogTitle)`
    width: 100%;
`;
DialogTitle.displayName = "DialogTitle";

const DialogContainer = styled.div`
    width: 30vw;
    max-width: 600px;
`;

const Modal = ({
    open, text, onConfirm, onClose,
}) => (
    <Dialog
        open={open}
        onClose={onClose}
    >
        <DialogContainer>
            <DialogTitle>{text}</DialogTitle>
            <DialogActions>
                <Button
                    onClick={onConfirm}
                    color="primary"
                    id="btn-modal-confirm"
                >
                    Confirm
                </Button>
            </DialogActions>
        </DialogContainer>
    </Dialog>
);

Modal.propTypes = {
    open: PropTypes.bool,
    text: PropTypes.string,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
};

Modal.defaultProps = {
    open: false,
    text: "Are you sure?",
    onConfirm: () => {},
    onClose: () => {},
};

export default Modal;
