import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as styles from "../styles";

const HeaderCell = styled(TableCell)`
    width: 50%;
`;

const DynamicDataImportSummaryTable = ({ collectionValues }) => (
    collectionValues.map((entry) => (
        <Accordion key={entry.name} style={styles.accordionWidth}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={styles.accordionSummary}>{entry.name}</Typography>
                <Typography>{`${entry.options.length} Options`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <HeaderCell>Option</HeaderCell>
                            <HeaderCell>Condition</HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entry.options.map((option) => (
                            <TableRow key={option.name}>
                                <TableCell>{option.name}</TableCell>
                                <TableCell>
                                    {option.condition.length
                                        ? `${option.condition[0].typeName} = 
                                            ${option.condition.map((y) => y.optionName).join(" | ")}`
                                        : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    ))
);

DynamicDataImportSummaryTable.propTypes = {
    collectionValues: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({})),
    })),
};

export default DynamicDataImportSummaryTable;
