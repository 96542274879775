import React from "react";
import { useHistory } from "react-router-dom";
import { Page } from "components/Page";
import { Section, SectionTitle, SectionContent } from "components/Page/Section";
import options from "./options";
import ConfigImportButton from "./ConfigImportButton/index";

const ImportSelection = () => {
    const { push, location: { pathname } } = useHistory();

    const handleClick = (type) => {
        const rootPath = pathname.split("/").slice(0, 4).join("/");
        push(`${rootPath}/${type}`);
    };

    return (
        <Page>
            <Section>
                <SectionTitle title="Configuration Import" />
                <SectionContent>
                    {options.map((option, index) => (
                        <ConfigImportButton
                            key={`${index}: ${option.name}`}
                            option={option}
                            onClick={() => handleClick(option.type)}
                        />
                    ))}
                </SectionContent>
            </Section>
        </Page>
    );
};

export default ImportSelection;
