import React, { useState } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { Page, Section } from "components/Page";
import DetailsSection from "../DetailsSection";

const initialState = {
    id: null,
    name: null,
    domain: null,
    website: null,
    licenseTemplateId: "d78a2339-bb40-4748-bc4e-cf5e8a012eb2",
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    height: 100px;
    padding: 0 30px;
    justify-content: center;
    background-color: ${({ theme }) => theme.brand};
    color: #fff;
`;
Header.displayName = "Header";

const AddCompany = () => {
    const [company, setCompany] = useState(initialState);

    const updateHeader = (companyName) => {
        setCompany((prevState) => ({ ...prevState, name: companyName }));
    };

    return (
        <Container>
            <Header>
                <Typography variant="h4">{company.name || "New Company"}</Typography>
            </Header>
            <Page>
                <Section>
                    <DetailsSection company={company} onHeaderUpdate={updateHeader} newCompany />
                </Section>
            </Page>
        </Container>
    );
};

export default AddCompany;
