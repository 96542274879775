import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
    ButtonGroup, Button, TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

const ListControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px ${({ theme }) => theme.greyscale.grey2};
`;

export const FilterButtons = styled(ButtonGroup)`
    border-radius: 0;
    margin: 10px 0;
    padding: 0 10px;
`;

const FilterButton = styled(Button)`
    border-radius: 0;
    background-color: ${({ theme }) => theme.greyscale.white};
    color: ${({ theme }) => theme.greyscale.grey2};
    border-bottom: solid 2px ${({ theme }) => theme.greyscale.grey2};
    box-shadow: none;

    &.MuiButton-containedSecondary {
        color: ${({ theme }) => theme.brand};
        border-bottom: solid 2px ${({ theme }) => theme.brand};
        font-weight: bold;

        &:hover {
            color: ${({ theme }) => theme.brand};
            background-color: ${({ theme }) => theme.greyscale.white};
            border-bottom: solid 2px ${({ theme }) => theme.brand};
            box-shadow: none;
        }
    }

    &:hover {
        background-color: ${({ theme }) => theme.background};
        color: ${({ theme }) => theme.brand};
        border-bottom: solid 2px ${({ theme }) => theme.brand};
        box-shadow: none;
    }
`;
FilterButton.displayName = "FilterButton";

const SearchBoxContainer = styled.div`
    display: flex;
`;

const SearchBoxIcon = styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: ${({ theme }) => theme.greyscale.grey3};
`;

const SearchBox = styled(TextField)`
    border: 0;
    flex: 1 1 auto;
    height: 46px;
    margin-right: 10px;

    div {
        height: 100%;

        &.MuiInput-underline:hover:before,
        &.MuiInput-underline:before {
            border:0;
        }

        &.MuiInput-underline:hover:after,
        &.MuiInput-underline:after {
            border:0;
        }
    }
`;
SearchBox.displayName = "SearchBox";

const AddButton = styled.button`
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.brand};
    background-color: transparent;
    margin: 0 10px;
    border: 0;
    font-weight: bold;
    cursor: pointer;
`;
AddButton.displayName = "AddButton";

const ListSubText = styled.div`
    margin: 0 10px;
    opacity: 0.74;
`;

const buttons = ["enabled", "disabled", "all"];

const ListControls = ({
    onSearchChange, currentFilter, onFilterClick, onAddClick, subText,
}) => (
    <ListControlsContainer>
        <FilterButtons fullWidth>
            {buttons.map((button, index) => (
                <FilterButton
                    key={index}
                    color={currentFilter === button ? "secondary" : "default"}
                    variant="contained"
                    size="small"
                    onClick={() => onFilterClick(button)}
                >
                    {button}
                </FilterButton>
            ))}
        </FilterButtons>
        <SearchBoxContainer>
            <SearchBoxIcon>
                <SearchIcon />
            </SearchBoxIcon>
            <SearchBox
                color="secondary"
                fullWidth
                placeholder="Search"
                type="search"
                onChange={onSearchChange}
            />
            <AddButton onClick={onAddClick}>
                <AddIcon />
                Add
            </AddButton>
        </SearchBoxContainer>
        <ListSubText>
            {subText}
        </ListSubText>
    </ListControlsContainer>
);

ListControls.propTypes = {
    onSearchChange: PropTypes.func.isRequired,
    onFilterClick: PropTypes.func,
    onAddClick: PropTypes.func.isRequired,
    currentFilter: PropTypes.string,
    subText: PropTypes.string,
};

ListControls.defaultProps = {
    onFilterClick: () => {},
    currentFilter: "enabled",
    subText: "",
};

export default ListControls;
