/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SmallDeleteButton from "../Buttons/SmallDeleteButton";

const ListItemRow = styled.div`
    display: flex;
    padding: 5px;
`;

const KeyField = styled(TextField)`
    min-width: 45%;
    margin-right: 2%;
`;
KeyField.displayName = "KeyField";

const ValueField = styled(TextField)`
    min-width: 45%;
    margin-right: 2%;
`;
ValueField.displayName = "ValueField";

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 5%;
`;

const CopyButton = styled.button`
    display: flex;
    background-color: ${({ theme }) => theme.greyscale.white};
    border: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
        width: 1.2rem;
        height: 1.2rem;
        fill: ${({ theme }) => theme.greyscale.grey1};
    }
`;
CopyButton.displayName = "CopyButton";

const ListItemView = ({
    optionKey: key,
    optionValue: initialValue,
    showDeleteButton,
    showCopyButton,
    onDelete,
    onUpdate,
}) => {
    const [value, setValue] = useState(initialValue);

    const handleValueChange = ({ target }) => setValue(target.value);

    const handleValueBlur = () => {
        if (value !== initialValue) {
            onUpdate(key, value);
        }
    };

    const copyToClipboard = async (text) => {
        await navigator.clipboard.writeText(text);
    };

    return (
        <ListItemRow>
            <KeyField
                disabled
                value={key}
                color="secondary"
                InputProps={showCopyButton && {
                    endAdornment: (
                        <CopyButton
                            size="small"
                            onClick={() => copyToClipboard(key)}
                        >
                            <FileCopyIcon />
                        </CopyButton>),
                }}
            />
            <ValueField
                value={value}
                color="secondary"
                onChange={handleValueChange}
                onBlur={handleValueBlur}
            />
            {showDeleteButton
            && <ButtonContainer>
                <SmallDeleteButton onDelete={() => onDelete(key)} />
            </ButtonContainer>}
        </ListItemRow>
    );
};

ListItemView.propTypes = {
    optionKey: PropTypes.string,
    optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    showDeleteButton: PropTypes.bool,
    showCopyButton: PropTypes.bool,
    onDelete: PropTypes.func,
    onUpdate: PropTypes.func,
};

export default ListItemView;
