import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import {
    Avatar, ListItem, ListItemAvatar, ListItemText,
} from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import { useCompanyContext } from "context/CompanyContext/CompanyContext";
import { SecondaryMenu, ListControls } from "components/SecondaryMenu";
import { sortByField } from "utils/arrayHelpers";
import ListContainer from "components/Page/Containers/ListContainer";
import * as Constants from "domain/Router/RouterConstants";

const filters = {
    all: (x) => x,
    enabled: (x) => x.enabled,
    disabled: (x) => !x.enabled,
};

const CompanyListItem = styled(ListItem)`

    &:hover {
        background-color: ${({ theme }) => theme.background};

        .MuiListItemText-secondary {
            color: ${({ theme }) => theme.brand}
        }

        .MuiAvatar-colorDefault {
            color: ${({ theme }) => theme.greyscale.white};
            background-color: ${({ theme }) => theme.brand};
        }
    }

    &.Mui-selected {
        background-color: ${({ theme }) => theme.background};

        .MuiListItemText-secondary {
            color: ${({ theme }) => theme.brand}
        }

        .MuiAvatar-colorDefault {
            color: ${({ theme }) => theme.greyscale.white};
            background-color: ${({ theme }) => theme.brand};
        }
    }

    .MuiListItemText-primary {
        font-weight: bold;
    }

    .MuiAvatar-colorDefault {
        color: ${({ theme }) => theme.greyscale.black};
        background-color: ${({ theme }) => theme.greyscale.grey5};
    }
`;
CompanyListItem.displayName = "CompanyListItem";

const CompanyList = () => {
    const { companies, error, loading } = useCompanyContext();
    const [selectedFilter, setFilter] = useState("enabled");
    const [searchTerm, setSearchTerm] = useState(null);

    const history = useHistory();
    const location = useLocation();
    const companyId = location.pathname.split("/")[2];

    const handleNavigate = (path) => history.push(path);
    const filterCompanyList = (event) => setSearchTerm(event.target.value);
    const selectCompany = (id) => handleNavigate(`/${Constants.COMPANY}/${id}/${Constants.COMPANY_DETAILS}`);
    const addCompany = () => handleNavigate(`/${Constants.COMPANY}/new/${Constants.COMPANY_DETAILS}`);

    let sortedCompanies = [];
    let filteredCompanies = [];

    if (companies) {
        sortedCompanies = sortByField(companies, "name");
        filteredCompanies = sortedCompanies.filter(filters[selectedFilter]);
        if (searchTerm) {
            filteredCompanies = filteredCompanies
                .filter((c) => c.name.toLowerCase().includes(searchTerm.toLowerCase()) || c.domain.toLowerCase().includes(searchTerm.toLowerCase()));
        }
    }

    return (
        <SecondaryMenu loading={loading} error={error}>
            <ListControls
                onSearchChange={filterCompanyList}
                onFilterClick={setFilter}
                onAddClick={addCompany}
                currentFilter={selectedFilter}
                subText={`Showing ${filteredCompanies.length} items`}
            />
            <ListContainer>
                {filteredCompanies.map((
                    {
                        name,
                        id,
                        domain,
                    }, index
                ) => (
                    <Fragment key={`${index}-${name}`}>
                        <CompanyListItem
                            button
                            onClick={() => selectCompany(id)}
                            selected={companyId === id}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <BusinessIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={name} secondary={domain} />
                        </CompanyListItem>
                    </Fragment>
                ))}
            </ListContainer>
        </SecondaryMenu>
    );
};

export default CompanyList;
