/* eslint-disable react/jsx-closing-tag-location */
import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "components/Page/Section/SectionTitle";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import * as dateHelpers from "utils/dateHelpers";

const Subtitle = styled(Typography)`
    font-weight: 600;
    min-width: 200px;
`;

const Divider = styled.hr`
    height: 2px;
    border-width: 0;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 5px;
`;

const RegistrationSection = ({ registration }) => (
    <>
        <SectionTitle title="Registration Details" />
        {registration != null
            ? <ContentContainer>
                <Row>
                    <Subtitle variant="subtitle2">Date:&nbsp;</Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="registration-registrationDate"
                    >
                        {registration.timestamp
                            ? dateHelpers.getLocalTime(registration.timestamp, "DD/MM/YYYY HH:mm")
                            : "N/A"}
                    </Typography>
                </Row>
                <Divider />
                <Row>
                    <Subtitle variant="subtitle2">First Name:&nbsp;</Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="registration-firstName"
                    >
                        {registration.firstName ? registration.firstName : "N/A"}
                    </Typography>
                </Row>
                <Divider />
                <Row>
                    <Subtitle variant="subtitle2">Last Name:&nbsp;</Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="registration-secondName"
                    >
                        {registration.lastName ? registration.lastName : "N/A"}
                    </Typography>
                </Row>
                <Divider />
                <Row>
                    <Subtitle variant="subtitle2">Email Address:&nbsp;</Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="registration-emailAddress"
                    >
                        {registration.emailAddress ? registration.emailAddress : "N/A"}
                    </Typography>
                </Row>
                <Divider />
                <Row>
                    <Subtitle variant="subtitle2">Phone Number:&nbsp;</Subtitle>
                    <Typography
                        variant="body1"
                        data-testid="registration-phoneNumber"
                    >
                        {registration.phoneNumber ? registration.phoneNumber : "N/A"}
                    </Typography>
                </Row>
            </ContentContainer>
            : <ContentContainer>
                There are no registration details available
            </ContentContainer>}
    </>
);

RegistrationSection.propTypes = {
    registration: PropTypes.shape({
        timestamp: PropTypes.string,
        ipAddress: PropTypes.string,
        emailAddress: PropTypes.string,
        phoneNumber: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
};

export default RegistrationSection;
