import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import Toolbar from "components/MaterialUi/Toolbar";
import AppBar from "components/MaterialUi/AppBar";

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
`;

const PageHeader = ({ title, subTitle }) => (
    <AppBar position="static" color="secondary">
        <Toolbar>
            <TitleContainer>
                <div>
                    <Typography variant="h4">{title}</Typography>
                    <Typography variant="subtitle2">{subTitle}</Typography>
                </div>
            </TitleContainer>
        </Toolbar>
    </AppBar>
);

PageHeader.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
};

export default PageHeader;
